<template>
  <ion-page>
    <ion-header>
      <MainToolbar isSubpage :title="i18n.$t('analysis.title.examination')" :discardQuestion="i18n.$t('services.edit.discard_question')" :confirmDiscard="isEditingServices && changedServicesExist"/>
    </ion-header>
    <ion-content id="analysis-scroll-content">
      <div class="status-information" v-if="selectedAnimal != null">
        <ion-card class="horse-info-status" :style="selectedAnimal.color != null ? `--custom-color: ${selectedAnimal.color}` : undefined"> <!-- TODO At some point make fur colors/patterns in images and here!-->
          <div v-if="selectedAnimal.image != null" class="horse-image-large">
            <AuthenticatedMedia cssClass="background" :mediaUrl="selectedAnimal.image.blobURL" type="image"></AuthenticatedMedia>
            <AuthenticatedMedia :mediaUrl="selectedAnimal.image.blobURL" type="image"></AuthenticatedMedia>
          </div>
          <div class="horse-color-large"></div>
          <ion-card-header>
            <ion-card-title class="animal-select">
              <AnimalSelect
                :selectedAnimalId="animalId"
                @update:selectedAnimalId="chooseSubject"
                :availableAnimals="availableHorses">
              </AnimalSelect>
            </ion-card-title>
            <div class="animal-buttons">
              <ion-button class="qr-button" fill="clear" shape="round" @click="generateQR(selectedAnimal)">
                <ion-icon :icon="qrCode"></ion-icon>
              </ion-button>
              <ion-button class="edit-button" fill="clear" shape="round" @click="editAnimal(animalId)">
                <ion-icon :icon="pencil"></ion-icon>
              </ion-button>
            </div>
          </ion-card-header>
          <ion-card-content>
            <ion-label class="horse-info-label">
              <h2 class="text-with-icon">
                <font-awesome-icon :icon="faHashtag" />
                <span>{{ selectedAnimal.unique_identifier || noValuePlaceholder }}</span>
              </h2>
              <h2 class="text-with-icon">
                <font-awesome-icon :icon="faMarsAndVenus" />
                <span>{{ ((selectedAnimal.additional != null) ? translateAttribute(selectedAnimal.additional.gender, 'gender') : noValuePlaceholder) || noValuePlaceholder }}</span>
              </h2>
              <h2 class="text-with-icon">
                <font-awesome-icon :icon="faDna" />
                <span>{{ ((selectedAnimal.additional != null) ? translateAttribute(selectedAnimal.additional.race, 'race') : noValuePlaceholder) || noValuePlaceholder }}</span>
              </h2>
              <h2 class="text-with-icon">
                <font-awesome-icon :icon="faCakeCandles" />
                <span v-if="selectedAnimal.additional != null && (selectedAnimal.additional.birthday != null || selectedAnimal.additional.birthyear != null)">
                  {{ getSimpleLocalDate(selectedAnimal.additional.birthday, ageDateFormat) || selectedAnimal.additional.birthyear }}
                  ({{ i18n.$t('animal.attributes.additional.age') }}:
                  <!-- Calculate either from the exact birthday or just the year (Handle calculation there differently) -->
                  <template v-if="selectedAnimal.additional.birthday == null">
                    ~ <!-- Just an estimate from the year -->
                    {{ calculateAgeFromBirthdate(selectedAnimal.additional.birthyear, ageYearFormat) }})
                  </template>
                  <template v-else>
                    {{ calculateAgeFromBirthdate(selectedAnimal.additional.birthday) }})
                  </template>
                </span>
                <span v-else-if="selectedAnimal.additional != null && selectedAnimal.additional.age != null">
                  {{ i18n.$t('animal.attributes.additional.age') }}: {{ selectedAnimal.additional.age }}
                </span>
                <span v-else>
                  {{ noValuePlaceholder }}
                </span>
              </h2>
              <h2 class="text-with-icon multiline-container">
                <font-awesome-icon :icon="faAddressCard" />
                <div class="multiline" v-if="selectedAnimal.personal_data != null && objectContainsNonNullValues(selectedAnimal.personal_data)">
                  <span v-if="selectedAnimal.personal_data.name != null">{{ selectedAnimal.personal_data.name }}</span>
                  <span v-if="selectedAnimal.personal_data.phone != null">
                    <a class="contact-link" :href="`tel:${selectedAnimal.personal_data.phone}`">{{ selectedAnimal.personal_data.phone }}</a>
                  </span>
                  <span v-if="selectedAnimal.personal_data.address != null">{{ selectedAnimal.personal_data.address }}</span>
                  <span v-if="selectedAnimal.personal_data.email != null">
                    <a class="contact-link" :href="`mailto:${selectedAnimal.personal_data.email}`">{{ selectedAnimal.personal_data.email }}</a>
                  </span>
                </div>
                <span v-else>
                  {{ noValuePlaceholder }}
                </span>
              </h2>
              <h2 class="text-with-icon multiline-container">
                <font-awesome-icon :icon="faUserDoctor" />
                <div class="multiline" v-if="selectedAnimal.personal_data_vet != null && objectContainsNonNullValues(selectedAnimal.personal_data_vet)">
                  <span v-if="selectedAnimal.personal_data_vet.veterinarian != null">{{ selectedAnimal.personal_data_vet.veterinarian }}</span>
                  <span v-if="selectedAnimal.personal_data_vet.veterinarian_phone != null">
                    <a class="contact-link" :href="`tel:${selectedAnimal.personal_data_vet.veterinarian_phone}`">{{ selectedAnimal.personal_data_vet.veterinarian_phone }}</a>
                  </span>
                  <span v-if="selectedAnimal.personal_data_vet.veterinarian_email != null">
                    <a class="contact-link" :href="`mailto:${selectedAnimal.personal_data_vet.veterinarian_email}`">{{ selectedAnimal.personal_data_vet.veterinarian_email }}</a>
                  </span>
                </div>
                <span v-else>
                  {{ noValuePlaceholder }}
                </span>
              </h2>
              <div class="more-info-container">
                <ion-button class="more-info" @click="editAnimal(animalId, true)">
                  <ion-icon slot="start" :icon="ellipsisHorizontal"></ion-icon>
                  {{ i18n.$t('analysis.subject.more_info') }}
                </ion-button>
              </div>
            </ion-label>
          </ion-card-content>
        </ion-card>

        <ion-card class="quick-analysis-card">
          <ion-card-header>
            <ion-card-title><AnimatedLogo name="crosshair-fade" intensity="medium" duration="2000" stopPattern="cross" class="logo title-icon"></AnimatedLogo><ion-label>{{ i18n.$t('analysis.quick-analysis.title') }}</ion-label></ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-list class="quick-analysis-options">
              <ion-item v-for="option in quickAnalysisOptions" :key="option.key" detail button lines="full" @click="performQuickAnalysis(option)">
                <ion-label>
                  <h1>{{ i18n.$t(`analysis.quick-analysis.options.${option.key}.descriptor`) }}</h1>
                  <p>{{ i18n.$t(`analysis.quick-analysis.options.${option.key}.description`) }}</p>
                </ion-label>
                <ion-icon :icon="option.icon" slot="end" class="media-icon"></ion-icon>
              </ion-item>
            </ion-list>
          </ion-card-content>
        </ion-card>

        <ion-card class="status-tracking-card">
          <ion-card-header>
            <ion-card-title><font-awesome-icon class="status-tracking-icon title-icon" :icon="faRectangleList" /><ion-label>{{ i18n.$t('analysis.status-tracking.title') }}</ion-label></ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-list class="status-tracking-options">
              <template v-for="option in statusTrackingOptionsWithInformation" :key="option.descriptor">
                <ion-item v-if="isVeterinarian || !(option.pro_only)" lines="full" class="outer-status-tracking-item"> 
                  <ion-item lines="none" class="inner-status-tracking-item" :detail="option.count > 0" :button="option.count > 0" @click="option.count > 0 ? openStatusOverview(option.descriptor) : undefined">
                    <ion-label>
                      <h2 class="status-descriptor">
                        <font-awesome-icon :icon="option.icon" />
                        {{ getLabelCurrentLocaleOrCapitalize(option, option.descriptor) }}
                        <ion-badge :class="(!(option.count)) ? 'empty' : undefined">{{ option.count || 0 }}</ion-badge>
                      </h2>
                      <template v-if="option.latest != null">
                        <p class="status-latest-timestamp" v-if="option.latest.timestamp != null">{{ i18n.$t('analysis.status-tracking.last_entry') }}: <b>{{ getSimpleLocalDateAndTime(option.latest.timestamp) }}</b></p>
                        <p v-if="option.latest.details != null" class="status-details">
                          <template v-for="detail in option.details" :key="detail.descriptor">
                            <span v-if="detail.descriptor in option.latest.details" >
                              <b>{{ getLabelCurrentLocaleOrCapitalize(detail, detail.descriptor) }}</b> &centerdot; {{ option.latest.details[detail.descriptor] }} {{ detail.unit }}
                            </span>
                          </template>
                          <span v-if="option.latest.images != null" >
                            <font-awesome-icon :icon="faImage" /> &centerdot; {{ option.latest.images.length }}
                          </span>
                        </p> 
                      </template>
                      <p v-else>{{ i18n.$t('analysis.status-tracking.no_entries') }}</p>
                      
                    </ion-label>
                  </ion-item>
                  <ion-button slot="end" class="add-status-button" fill="solid" shape="round" @click="addStatus(option.descriptor)">
                    <ion-icon :icon="add" slot="icon-only" class="add-status-icon"></ion-icon>
                  </ion-button>
                </ion-item>
              </template>
            </ion-list>
          </ion-card-content>
        </ion-card>

        <ion-card v-if="!isVeterinarian" class="user-questions-card">
          <ion-card-header>
            <ion-card-title><font-awesome-icon class="health-status-icon title-icon" :icon="faNotesMedical" /><ion-label>{{ i18n.$t('analysis.user-questions.health-status') }}</ion-label></ion-card-title>
            <ion-card-subtitle :class="['health-status-subtitle']">
              <h1>{{ i18n.$t('analysis.user-questions.title') }}</h1>
              <h2>
                <span>{{ i18n.$t('analysis.user-questions.document') }}</span>
                <span>{{ i18n.$t('analysis.user-questions.prepare') }}</span>
              </h2>
            </ion-card-subtitle>
          </ion-card-header>
          <ion-card-content>
            <template v-for="questionStage, questionStageIndex in userQuestionStages" :key="questionStage.key">
              <div :class="['question-container', (isQuestionStageDependencySatisfied(questionStage)) ? undefined : 'hidden-element']">
                <ion-label class="question-header" :ref="(el) => setQuestionStageRef(questionStageIndex, el)">
                  <h1> {{ i18n.$t(`analysis.user-questions.${getQuestionStageKeyWithDependency(questionStage)}.question`) }} </h1>
                  <h3 v-if="questionStage.multiple"> {{ i18n.$t('analysis.user-questions.multiple') }} </h3>
                </ion-label>
                <ion-list class="question-option-list">
                  <template v-if="questionStage.multiple"> <!-- TODO Get checked value through computed function for checkbox and radio -->
                    <ion-item v-for="questionOption in questionStage.options" :key="questionOption.key">
                      <ion-label>{{ i18n.$t(`analysis.user-questions.${getQuestionStageKeyWithDependency(questionStage)}.options.${questionOption.key}`) }}</ion-label>
                      <ion-checkbox slot="start" @ionChange="selectUserQuestionChoice(questionStage.key, questionOption.key, $event.target.checked, questionStage.multiple, questionStageIndex)" :value="questionOption.key"/>
                    </ion-item>
                  </template>
                  <ion-radio-group v-else @ionChange="selectUserQuestionChoice(questionStage.key, $event.target.value, true, questionStage.multiple, questionStageIndex)" :value="userQuestionStageChoices[questionStage.key]" allowEmptySelection>
                    <ion-item v-for="questionOption in questionStage.options" :key="questionOption.key">
                      <ion-label>{{ i18n.$t(`analysis.user-questions.${getQuestionStageKeyWithDependency(questionStage)}.options.${questionOption.key}`) }}</ion-label>
                      <ion-radio mode="md" slot="start" :value="questionOption.key"/>
                    </ion-item>
                  </ion-radio-group>
                </ion-list>
              </div>
            </template>

            <div :class="['question-container', (areAllQuestionsAnswered) ? undefined : 'hidden-element']">
              <ion-label class="question-header" :ref="(el) => setQuestionStageRef(userQuestionStages.length, el)">
                <template v-if="selectedEntryTypeCount > 0">
                  <h1>{{ i18n.$t('analysis.user-questions.continue.before_protocol_count') }} {{ selectedEntryTypeCount }} {{ i18n.$t('analysis.user-questions.continue.after_protocol_count') }}</h1>
                  <h3>{{ i18n.$t('analysis.user-questions.continue.before_icon') }} <mark class="continue-icon-hint-container"><font-awesome-icon :icon="faNotesMedical" class="continue-icon-hint" /></mark> {{ i18n.$t('analysis.user-questions.continue.after_icon') }}</h3>
                </template>
                <template v-else>
                  <h1>{{ i18n.$t('analysis.user-questions.continue.not_loaded') }}</h1>
                  <h3>{{ i18n.$t('error.try_later') }}</h3>
                </template>
              </ion-label>
            </div>
          </ion-card-content>
        </ion-card>

        <ion-card v-if="isVeterinarian" class="examination-card">
          <ion-card-header>
            <ion-card-title><ion-icon :icon="gitCommit" class="stage-icon title-icon"></ion-icon><ion-label>{{ i18n.$t('analysis.protocols.examination_stage') }}</ion-label></ion-card-title>

            <ion-card-subtitle :class="['choose-stage-subtitle']">{{ i18n.$t('analysis.protocols.choose_examination_stage') }}</ion-card-subtitle>
          </ion-card-header>

          <ion-card-content>
            <ExaminationStage id="stage-indicator" v-model:selectedStage="selectedStage" :stageOptions="availableStages" :completedStages="[]" enableSelect :preSelectFirstIncompleteStage="false"></ExaminationStage> <!-- TODO Make completedStages computed -->
          </ion-card-content>

          <CollapsibleList class="examination-selection" v-model:open="isExaminationSelectionOpen"
            :title="i18n.$t('analysis.protocols.start_examination')">

            <ion-card-title class="select-protocol-title"><font-awesome-icon :icon="faStethoscope" class="select-protocol-icon title-icon" /><ion-label>{{ i18n.$t('analysis.protocols.choose_protocols') }}</ion-label></ion-card-title>

            <CategorizedEntryTypeSelection
              v-model:selectedEntryTypes="selectedEntryTypes"
              :currentSelectedStage="selectedStage"
              :reportTypeIndex="reportTypeIndex"
              :hiddenEntryTypeStructures="hiddenEntryTypeStructures"
              @setEntryTypeHidden="setEntryTypeHidden"
              @update:protocolHidingMode="updateProtocolHidingMode">
            </CategorizedEntryTypeSelection>
          </CollapsibleList>
        </ion-card>
      </div>

      <!-- TODO In the future there could also be a section for tasks that have to be completed, that got sent or requested by someone through share (internal or external). As links to the history with correct filters applied! Either for the current case or for all! Maybe then grouped by case! -->
      
      <!--<ion-card class="provided-services-list-card">
        <div class="sticky-header-container">
          <ion-card-header>
            <ion-card-title><font-awesome-icon class="services-icon" :icon="faReceipt" /> {{ i18n.$t('services.provided') }}</ion-card-title>
            <ion-buttons class="services-edit-buttons">
              <ion-button v-if="!isEditingServices" id="add-services-button" color="primary" @click="setAddServiceOpen(true, $event)" fill="solid" shape="round">
                <ion-icon :icon="add"></ion-icon>
              </ion-button>
              <ion-popover
                :is-open="isAddServicePopupOpen"
                css-class="add-service-popup"
                :event="addServicePopupEventRef"
                @didDismiss="setAddServiceOpen(false)"
              >
                <ion-list>
                  <ion-list-header class="add-service-header">{{ i18n.$t('services.add.title') }}</ion-list-header>
                  <ion-item v-for="serviceDescriptor, serviceKey in availableServiceTypes" :key="serviceKey" detail="false" lines="none" button @click="addService(false); setAddServiceOpen(false);">
                    <span class="service-descriptor">{{ i18n.$t('services.add.from') }} <b>{{ serviceDescriptor }}</b></span>
                  </ion-item>
                  <ion-item class="add-custom-service" detail="false" lines="none" button @click="addService(true); setAddServiceOpen(false);">
                    {{ i18n.$t('services.add.custom') }}
                  </ion-item>
                </ion-list>
              </ion-popover>


              <ExtendableChip
                v-if="isEditingServices"
                class="cancel-services-edit-button"
                :color="changedServicesExist ? 'danger' : 'dark'"
                :title="((changedServicesExist) ? i18n.$t('services.edit.discard_question') : undefined)"
                :extendOnClick="changedServicesExist"
                @extendedClick="cancelEditingServices()"
                :enableClosedClick="!(changedServicesExist)"
                @closedClick="cancelEditingServices()"
                extendToLeft
                button
                textIcon
                :collapseTimeout="5000"
                >
                <template v-slot:permanent>
                  <ion-icon :icon="close" :alt="i18n.$t('services.edit.discard_question')"></ion-icon>
                </template>
              </ExtendableChip>
              <ion-button id="edit-services-button" color="success" :disabled="isEditingServices && !(changedServicesExist)" @click="toggleServiceEditing" :fill="(isEditingServices) ? ((changedServicesExist) ? 'solid' : 'outline') : 'clear'" shape="round">
                <ion-icon v-if="isEditingServices" :icon="save"></ion-icon>
                <ion-icon v-else :icon="pencil"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-card-header>
          <div class="invoice-creation">
            <div class="invoice-selection-icon">
              <ion-icon :icon="arrowDown"></ion-icon>
            </div>
            <ion-button class="multi-selection-button" :disabled="!isSelectingServices" fill="solid" @click="toggleSelectDeselectAll">
              <ion-icon :icon="checkboxOutline" class="all"></ion-icon>
              <hr class="angled-separator">
              <ion-icon :icon="squareOutline" class="none"></ion-icon>
            </ion-button>
            
            {{ i18n.$t('services.invoice.select') }}

            <ion-button class="create-invoice-button" :disabled="!areServicesSelected || !isSelectingServices">
              <ion-icon :icon="arrowForward"></ion-icon>
              {{ i18n.$t('services.invoice.title') }}
              <font-awesome-icon class="create-invoice-icon" :icon="faFileInvoice" />
            </ion-button>
          </div>
        </div>

        <ServicesSelection ref="servicesSelectionRef" :selectMode="isSelectingServices" :editMode="isEditingServices" :defaultOpenLevel="0" :customElements="providedServices" @elementsUpdated="updateServices" v-model:selection="selectedServices"></ServicesSelection>
      </ion-card>-->

      <div id="over-scroll"></div>

      <!-- <ion-fab vertical="bottom" horizontal="start" slot="fixed">
        <ion-fab-button @click="addMetadataEntry()" class="metadata-add-button">
          <font-awesome-icon class="metadata-add-icon" :icon="faNoteSticky" />
          <font-awesome-icon class="metadata-add-icon-addition" :icon="faCommentMedical" />
        </ion-fab-button>
      </ion-fab> -->

      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button v-if="isExaminationSelectionOpen || areAllQuestionsAnswered" :disabled="(selectedEntryTypeCount < 1 || protocolHidingMode)" color="primary" @click="createReportTemplate()" class="continue-button"> <!-- TODO Add notification, if hiding mode is still on to show why we can't progress? -->
          <font-awesome-icon v-if="isVeterinarian" class="examination-icon" :icon="faStethoscope" />
          <font-awesome-icon v-else class="examination-icon" :icon="faNotesMedical" />
          <ion-icon class="continue-report-icon-addition" :icon="arrowForward"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </ion-content>
  </ion-page>
</template>

<script>
import { 
  IonPage, 
  IonHeader, 
  IonContent,
  IonLabel,
  IonIcon,
  IonCard, 
  IonCardContent, 
  IonCardHeader, 
  IonCardTitle, 
  IonCardSubtitle,
  IonButton,
  IonFab,
  IonFabButton,
  IonList,
  IonItem,
  IonRadioGroup,
  IonRadio,
  IonCheckbox,
  IonBadge,
  /*IonButtons,
  IonPopover,
  IonListHeader,*/
  getPlatforms
} from '@ionic/vue';
import { computed, ref, onMounted, onUnmounted, watch /*, defineAsyncComponent */ } from 'vue';
import { useStore } from 'vuex';

import MainToolbar from '@/components/MainToolbar.vue';
import ExaminationStage from '@/components/ExaminationStage.vue';
import CollapsibleList from '@/components/CollapsibleList.vue';
import CategorizedEntryTypeSelection from '@/components/CategorizedEntryTypeSelection.vue';
import AnimalSelect from '@/components/AnimalSelect.vue';
import AuthenticatedMedia from '@/components/AuthenticatedMedia.vue';

/*const ServicesSelection = defineAsyncComponent(() => import('@/components/ServicesSelection.vue'));

import ExtendableChip from '@/components/ExtendableChip.vue';*/

import { quickAnalysisOptions, statusTrackingOptions, userQuestionStages, questionEntryTypes } from '@/config/analysis';

import { openCameraModal, default as cameraModalComponent, parseCameraOptions } from '@/components/CameraModal.vue';

import { useEditAnimalModal } from '@/components/composables/EditAnimalModal.js';
import { useEditStatusModal } from '@/components/composables/EditStatusModal.js';
import { useEditMetadataModal } from '@/components/composables/EditMetadataModal.js';
import { default as AddServiceModalComponent, openAddServiceModal } from '@/components/AddServiceModal.vue';
import { default as StatusOverviewModalComponent, openStatusOverviewModal } from '@/components/StatusOverviewModal.vue';
import { default as QRGenerationModalComponent, openQRGenerationModal } from '@/components/QRGenerationModal.vue';

import { pencil, ellipsisHorizontal, arrowForward, gitCommit, close, save, arrowDown, squareOutline, checkboxOutline, add, filter, qrCode } from 'ionicons/icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faMarsAndVenus, faHashtag, faDna, faCakeCandles, faAddressCard, faStethoscope, faReceipt, faFileInvoice, faNotesMedical, faUserDoctor, faImage, faCommentMedical } from '@fortawesome/free-solid-svg-icons';
import { faNoteSticky, faRectangleList } from '@fortawesome/free-regular-svg-icons';

import { useI18n } from "@/utils/i18n";
import { hexToHue } from "@/utils/colors"
import { objectContainsNonNullValues } from '@/utils/algorithms';
import { useDayjs, ageYearFormat, ageDateFormat } from '@/utils/dayjs';
import { orderAndLocalizeReportTypeIndex, typeDefinitionToHierarchicalObject, parseKey, addKeyHierarchyAttributes, convertFieldToComponentType, TYPE_API_MAPPINGS, MULTIPLE_FILE_TYPE_MAPPINGS } from '@/utils/report';
import { serviceDefinitions, getIdentifyingAttributes } from '@/utils/services';
import { getLabelCurrentLocaleOrCapitalizeFunction } from '@/utils/animals';

import { useRouter } from 'vue-router';

import AnimatedLogo from '@/components/AnimatedLogo.vue';

//import PdfInvoice from "@/utils/pdf_invoice";

import { download, sanitizeFileOrFolderName } from '@/utils/file_download';

import _ from 'lodash';

export default  {
  name: 'Analysis',
  components: { 
    IonHeader, 
    IonContent, 
    IonPage, 
    MainToolbar, 
    IonLabel,
    IonIcon,
    IonCard, 
    IonCardContent, 
    IonCardHeader, 
    IonCardTitle, 
    IonCardSubtitle,
    IonButton,
    IonFab,
    IonFabButton,
    ExaminationStage, 
    CollapsibleList,
    CategorizedEntryTypeSelection,
    AnimalSelect,
    FontAwesomeIcon,
    AnimatedLogo,
    IonList,
    IonItem,
    IonRadioGroup,
    IonRadio,
    IonCheckbox,
    IonBadge,
    AuthenticatedMedia,
    /*ServicesSelection,
    ExtendableChip,
    IonListHeader,
    IonButtons,
    IonPopover*/
  },
  props: {
    animalId: String
  },
  setup(props) {
    const i18n = useI18n();

    const { dayjs, calculateAgeFromBirthdate, getSimpleLocalDate, getSimpleLocalDateAndTime } = useDayjs();

    const store = useStore();

    const router = useRouter();

    const isVeterinarian = computed(() => {
      return store.getters['auth/isVeterinarian'];
    });

    const userQuestionStageChoices = ref({});

    const userQuestionStageTitleElements = ref({});

    const setQuestionStageRef = function(index, element) {
      userQuestionStageTitleElements.value[index] = element;
    }

    const isQuestionStageDependencySatisfied = computed(() => {
      return function(questionStage) {
        return questionStage.dependsOn == null || (questionStage.dependsOn in userQuestionStageChoices.value && userQuestionStageChoices.value[questionStage.dependsOn] != null);
      }
    });

    const areAllQuestionsAnswered = computed(() => {
      return _.every(userQuestionStages, (questionStage) => questionStage.key in userQuestionStageChoices.value && userQuestionStageChoices.value[questionStage.key] != null);
    });

    const getQuestionStageKeyWithDependency = computed(() => {
      return function(questionStage) {
        if (questionStage.dependsOn != null && questionStage.dependsOn in userQuestionStageChoices.value) {
        return `${userQuestionStageChoices.value[questionStage.dependsOn]}_${questionStage.key}`;
        } else {
          return questionStage.key;
        }
      }
    });

    const selectUserQuestionChoice = function(questionStage, newValue, newState, multiple, questionIndex) {
      userQuestionStageChoices.value = _.update(userQuestionStageChoices.value, [questionStage], (value) => {
        //For multiple create an array with the value included / excluded from the existing array
        if (multiple) {
          if (newState == true) {
            return _.union(value || [], [newValue]);
          } else {
            return _.difference(value || [], [newValue]);
          }
        } else { //For single values, just overwrite and set to undefined if there is no value to be set
          if (newValue && newState == true) {
            return newValue;
          } else {
            return undefined;
          }
        }
      });

      //If it is defined after change, scroll to next one
      if (userQuestionStageChoices.value[questionStage]) {
        let nextIndex = questionIndex + 1;
        if (nextIndex in userQuestionStageTitleElements.value && userQuestionStageTitleElements.value[nextIndex] != null && userQuestionStageTitleElements.value[nextIndex].$el != null) {
          //Wait a short amount of time so it becomes visible before scrolling!
          setTimeout(() => userQuestionStageTitleElements.value[nextIndex].$el.scrollIntoView({ behavior: 'smooth', block: 'center' }), 100);
        }
      }

      let entryTypeIdentifierPaths = [[]];

      //For every step, take every previous step and mulitply with all options of the current step, so we get all the selected combinations
      _.forEach(userQuestionStages, (questionStage) => {
        let currentSelection = _.get(userQuestionStageChoices.value, questionStage.key);
        if (currentSelection != null) {
          if (!(Array.isArray(currentSelection))) {
            currentSelection = [currentSelection];
          }

          currentSelection = _.sortBy(currentSelection, (selectedOption) => _.findIndex(questionStage.options, {key: selectedOption}));

          entryTypeIdentifierPaths = _.flatMapDepth(currentSelection, (selection) => {
            return _.map(entryTypeIdentifierPaths, (identifierArray) => _.concat(identifierArray, [selection]));
          }, 1); 
        }
      });

      //Remove all incomplete identifierArrays
      entryTypeIdentifierPaths = _.filter(entryTypeIdentifierPaths, (identifierArray) => (identifierArray != null && identifierArray.length === userQuestionStages.length));

      //Transform each array path to the actual identifiers and remove ones that can't be found
      let entryTypeIdentifiers = _.compact(_.map(entryTypeIdentifierPaths, (identifierPath) => _.get(questionEntryTypes, identifierPath)));

      //Set the entry types that could be found
      selectedEntryTypes.value = _.map(store.getters['reports/getReportTypesByIdentifiers'](entryTypeIdentifiers), (entryType, longIdentifier) => {
        //For each entry type, the ID is either set to the short version or the original id that was used to look up the entry type
        return {
          id: _.get(entryType, ['identifiers', 'shortIdentifier'], longIdentifier)
        };//FIXME If they have stages etc. set, should set in the selectedEntryTypes, just like the CategorizedSelection does!
      });
    }

    const editAnimalModal = useEditAnimalModal();

    const editStatusModal = useEditStatusModal();

    const editMetadataModal = useEditMetadataModal();

    const selectedStageRef = ref(null);

    const isExaminationSelectionOpen = ref(false);

    const selectedStage = computed({
      get: () => selectedStageRef.value,
      set: (newStage) => {
        selectedStageRef.value = newStage;
        //Once one gets selected, open the protocol selection
        isExaminationSelectionOpen.value = true;
      }
    })

    const noValuePlaceholder = '—';

    let translationPrefix = 'animal.horse';

    const horseInfoPromise = computed(() => {
      return store.getters['horses/getHorseInfosWithCurrentStatusAsync'];
    });

    const availableHorses = ref(null);

    const selectedAnimal = ref(null);

    //If horse id does not exist, route back out of it
    watch([horseInfoPromise, () => props.animalId], ([animalPromise, id]) => {
      if (animalPromise != null) {
        animalPromise.then((animals) => {
          availableHorses.value = animals;
          if (id != null) {
            selectedAnimal.value = animals[id];
            if (!(id in animals)) {
              router.replace({name: 'subject-overview'});
            }
          }
        });
      }
    }, { immediate: true });

    const statusTrackingOptionsWithInformation = computed(() => {
      let currentStatusesForAnimalByType = _.get(selectedAnimal.value, ['status'], {});

      return _.map(statusTrackingOptions, (option) => {
        let currentType = _.get(currentStatusesForAnimalByType, [option.descriptor], {}); //Get the values fot that type or an empty array
        return {
          ...option,
          ...currentType
        }
      });
    });

    const hiddenEntryTypeStructures = computed(() => {
      return store.getters['customization/getHiddenEntryTypes'];
    })

    const selectedEntryTypes = ref([]);

    const availableServiceTypes = computed(() => {
      //TODO In future allow user to filter only relevant types for him
      return _.mapValues(serviceDefinitions, 'descriptor');
    });

    const servicesSelectionRef = ref(null);

    const selectedServices = ref([]);

    const areServicesSelected = computed(() => selectedServices.value != null && selectedServices.value.length > 0);

    const isEditingServices = ref(false);

    //Show selection always when not editing
    const isSelectingServices = computed(() => !isEditingServices.value);

    const toggleSelectDeselectAll = function() {
      //When some are selected, deselect all
      if (areServicesSelected.value) {
        selectedServices.value = [];
      } else { //When none are selected, select all by taking all valid identifiers from the provided services as a list
        selectedServices.value = _.compact(_.map(providedServices.value, (item) => {
          if (item == null) return null;
          let identifyingAttributes = getIdentifyingAttributes(item);
          if (identifyingAttributes == null || Object.keys(identifyingAttributes).length <= 0) return null;
          return identifyingAttributes;
        }));
      }
    }

    const cancelEditingServices = function() {
      isEditingServices.value = false;
      if (servicesSelectionRef.value != null) {
        servicesSelectionRef.value.resetItems();
      }
      changedServices.value = [];
    }

    const changedServices = ref([]);

    const changedServicesExist = computed(() => {
      return (changedServices.value != null && changedServices.value.length > 0);
    });

    const uploadServices = async function(newServices) {
      //TODO Implement upload
      console.log(newServices);
    }

    const toggleServiceEditing = async function() {
      if (isEditingServices.value && changedServicesExist.value) {
        await uploadServices(changedServices.value);
      }
      //Only close and remove changes on successful upload or if no changes exist
      isEditingServices.value = !(isEditingServices.value);
      if (servicesSelectionRef.value != null) {
        servicesSelectionRef.value.resetItems();
      }
      changedServices.value = [];
    }

    const updateServices = function(modifiedServices) {
      if (modifiedServices != null) {
        changedServices.value = Object.values(modifiedServices);
      }
    }

    const protocolHidingMode = ref(false);

    const updateProtocolHidingMode = function(newState) {
      protocolHidingMode.value = newState;
    }

    //ID is now a hash consisting of name and category path, so its unique and not dependant on the version!
    const setEntryTypeHidden = function({id, main_category, stage, sub_category, hidden}) {
      if (hidden) {
        store.dispatch('customization/hideEntryType', {main_category, stage, sub_category, id});
      } else {
        store.dispatch('customization/showEntryType', {main_category, stage, sub_category, id});
      }
    }

    const reportTypeIndex = computed(() => {
      //TODO Sort sub_categories and section somehow in model to have a order property here!
      let hierarchicalIndex = store.getters['reports/getHierarchicalReportTypeIndex'];
      return orderAndLocalizeReportTypeIndex(hierarchicalIndex, i18n, i18n.locale.value);
    });

    const availableStages = computed(() => { //FIXME It is in the entry type selection. Needs to be taken out maybe??? And then move the css over too!
      return _.map(reportTypeIndex.value, (stage) => {
        return _.pick(stage, 'descriptor', 'localizedDescriptor');
      });

      /*return [
        {
          descriptor: 'Vorsorge',
          'localizedDescriptor': 'Kontrolle Gesundheitsstatus'
        },
        {
          descriptor: 'Vorbericht',
          'localizedDescriptor': 'Vorbericht'
        },
        {
          descriptor: 'Allgemeinuntersuchung',
          'localizedDescriptor': 'Allgemeinuntersuchung'
        },
        {
          descriptor: 'Spezielle Untersuchungen',
          'localizedDescriptor': 'Spezielle Untersuchungen / Patientenüberwachung'
        },
        {
          descriptor: 'Labor',
          'localizedDescriptor': 'Labor'
        },
        {
          descriptor: 'Diagnose',
          'localizedDescriptor': '(Verdachts-) Diagnose'
        },
        {
          descriptor: 'Therapie',
          'localizedDescriptor': 'Therapie / Operation'
        },
        {
          descriptor: 'Nachsorge',
          'localizedDescriptor': 'Nachsorge'
        }
      ]*/
    });

    //TODO Add count of already filled out entry types. Here or in the entry type selection

    //TODO Everywhere, when choosing a horse for a report, take the newest id that the user owns. Maybe leave horse index then from /horses to determine the users horses!

    //TODO When multiple currencies are selected for creating an invoice, group them by the currency and create multiple invoices maybe with a sub number in the same document
    const providedServices = ref([
    {
      identifier: '30',
      id: '2',
      name: 'test30',
      'value': '102648.43',
      'factor': 3,
      section: 'A',
      category: "Grundleistungen",
      type: 'GOT',
      version: '20230315',
    },
    {
      identifier: '3',
      id: '3',
      name: 'test30',
      'value': '102648.43',
      'factor': 3,
      section: 'A',
      category: "Grundleistungen",
      type: 'GOT',
      version: '20230315',
    },
    {
      identifier: 'S10',
      name: 'testS10',
      'value': '10672648.43',
      'factor': 2,
      id: '6',
      section: 'A',
      category: "Grundleistungen",
    },  
    {
      identifier: '10',
      id: '5',
      section: 'A',
      category: "Grundleistungen",
      type: 'GOT',
      version: '20230315',
    },  
    {
      identifier: '1',
      id: '4',
      section: 'A',
      category: "Grundleistungen",
      type: 'GOT',
      version: '20230315',
    },  
    {
      identifier: '3',
      //id: '1', //TODO Test without ID!
      name: 'test',
      'value': '102648.43',
      'factor': 3,
      //type: 'GOT',
      //version: '20230315',
      custom: true
    },
    ]);

    //const pdf = ref(null);
    const pdfFileName = ref('Document'); //Default fallback filename
    const pdfBlob = ref(null);

    const isProcessingPDF = ref(false);

    const createInvoicePDF = async function(/* serviceList */){
      /*if (serviceList != null) {
        if (pdf.value != null){
          pdf.value.attachInvoice(serviceList);
        } else {
          pdf.value = new PdfInvoice(i18n, serviceList);
        }

        //Wait for processing to finish
        await pdf.value.promise;

        pdfBlob.value = pdf.value.export();
      }*/

      //Return already created PDFs
      return pdfBlob.value;
    }

    const createInvoice = function() { //TODO Move to a modal with settings for address etc.
      //TODO Give API the services to connect in an invoice
      isProcessingPDF.value = true;
      createInvoicePDF()
        .then((blob) => download(i18n, sanitizeFileOrFolderName(`${pdfFileName.value}.pdf`, '_'), blob, blob.type, getPlatforms()))
        .catch((error) => {
          console.error(error);
          return; //TODO No error handling?
        })
        .finally(() => isProcessingPDF.value = false);
    }

    const isAddServicePopupOpen = ref(false);
    const addServicePopupEventRef = ref();
    const setAddServiceOpen = function(state, event) {
      if ( isAddServicePopupOpen.value != state ){ //Only execute change, when it needs to be changed
        addServicePopupEventRef.value = event;
        if ( state == true ){
          if ( event !== undefined ){ //Only display when it can be positioned correctly with the event
            isAddServicePopupOpen.value = true;
          }
        } else {
          isAddServicePopupOpen.value = false;
        }
      }
    }

    const addService = function(customService = false) {
      //TODO Set service type when different services are possible
      openAddServiceModal(AddServiceModalComponent, { customService }).then(async (newServices) => {
        await uploadServices(newServices);

        //TODO Either add the changes here or use the update queue to show those as well
        providedServices.value = _.unionWith(newServices, providedServices.value, (newService, oldService) => {
          //Only if they both have a valid id set and the id is equal they are overwritten, otherwise, use both
          if (newService != null && oldService != null && newService.id != null && oldService.id != null && newService.id == oldService.id) return true;
          return false;
        })
      })
    }

    const handleResize = function(){
      setAddServiceOpen(false); //Close popover when resizing or rotating device screen for correct display
    }

    onMounted(() => {
      window.addEventListener("resize", handleResize);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    const chooseSubject = function(id) {
      router.replace({name: 'view-animal', params: { animalId: id }});
    }

    const editAnimal = function(id, viewMode = false) {
      editAnimalModal.open(id, null, viewMode);
    }

    const currentAnimalIdForPersonalInfoId = computed(() => {
      return store.getters['horses/getNewestHorseIdForPersonalInfoId'](props.animalId);
    })

    const addStatus = function(type) {
      editStatusModal.open(null, currentAnimalIdForPersonalInfoId.value, type);
    }

    const openStatusOverview = function(type) {
      openStatusOverviewModal(StatusOverviewModalComponent, selectedAnimal.value, props.animalId, type);
    }

    const addMetadataEntry = function() {
      editMetadataModal.open(null, props.animalId);
    }

    const generateQR = function() {
      openQRGenerationModal(QRGenerationModalComponent, [{ ...selectedAnimal.value, id: props.animalId }], 'animal', 'quaternary');
    }

    const createReportTemplate = function() {
      if (selectedEntryTypes.value != null) {
        //Map all selections to their ids and remove invalid ones (null values)
        let filteredSelectedIds = _.filter(_.map(selectedEntryTypes.value, 'id'), (identifier) => identifier != null); //TODO Order here affects order in the UI. Sort correctly beforehand in the order that it is in the selection list and by order of examination stage!!!

        if (filteredSelectedIds != null && filteredSelectedIds.length > 0) {
          //Navigate to CreateReport with all the ids as comma separated string
          router.push({name: 'create-report', params: { types: filteredSelectedIds.join(','), animalId: props.animalId }}); //TODO Keep in mind, that a descriptor could disappear, but still be selected. If just one protocol is missing on creation show an error, but continue with others!
        }
      }
    }

    const selectedEntryTypeCount = computed(() => {
      return selectedEntryTypes.value.length;
    });

    const getLocalTimeObject = computed(() => {
      return (isoString) => {
        return dayjs((new Date(isoString)));
      }
    });

    const performQuickAnalysis = async function(analyisParameters){
      //Get required report type and parse all fields required for capture and submit
      const typeIndexObject = store.getters['reports/getReportTypesByIdentifiers'](analyisParameters.entryTypeIdentifier);

      if (typeIndexObject == null) return;

      let type = await store.dispatch('reports/fetchReportType', typeIndexObject.id);

      if (type == null || type.definition == null) return;
      
      let typeDefinition = typeDefinitionToHierarchicalObject(type.definition);

      if (typeDefinition == null) return;

      let fieldKeyArray = addKeyHierarchyAttributes(parseKey(analyisParameters.fieldKey));

      let typeField = _.get(typeDefinition, fieldKeyArray);

      if (typeField == null || typeField.type == null) return;

      let cameraOptions = parseCameraOptions(typeField.type, _.get(typeField, 'capture_options', {}));

      let multipleFiles = (typeField.type in MULTIPLE_FILE_TYPE_MAPPINGS);

      return openCameraModal(cameraModalComponent, {
            ...cameraOptions
          },
          false, //Enable enter animation
          false, //Default leave animation
          true //Return before dismiss and start processing, not after animation, data is already set!
        ).then((capturedBlobData) => {
          if (capturedBlobData.data != null) {
            let filePromises = [];
            let capturedBlobs;
            if (Array.isArray(capturedBlobData.data)) {
              capturedBlobs = capturedBlobData.data;
            } else {
              capturedBlobs = [capturedBlobData.data];
            }

            //Convert all blobs to file objects
            for (let blob of capturedBlobs) {
              let blobURL = blob.url;
              filePromises.push(
                fetch(blobURL)
                  .then(fetchResult => fetchResult.blob())
                  .then(blobFile => {
                    return {
                      url: blobURL,
                      file: (new File([blobFile], props.key || 'capturedFile', { type: blob.type || blobFile.type })), //Use the given mimeType or read it from the blob
                    };
                  })
                  .catch((error) => {
                    //Return null if an error occurs
                    console.error(error);
                    return null;
                  })
              );
            }
            
            return Promise.all(filePromises).then((fileArray) => {
              //Only return files for which no error occured!
              return _.filter(fileArray, (fileObject) => fileObject != null);
            });
          }
        }).then(async (fileArray) => {
          if (fileArray != null && Array.isArray(fileArray)) {
            let fieldComponent = {
              'type': TYPE_API_MAPPINGS['files'][typeField.type],
              'key': analyisParameters.fieldKey,
              'uploaded_at': Date.now() //TODO Move this to the server
            }

            fieldComponent = convertFieldToComponentType(fieldComponent);

            let report = {
              'timestamp': Date.now(),
              'horse': currentAnimalIdForPersonalInfoId.value,
              'type': type.id,
              'location': 'stable',
              'control_examination': false,
              'fields': [
                fieldComponent
              ]
            }

            let fileInstances = _.map(fileArray, 'file');

            let files = {
              [`fields[0].${(multipleFiles) ? 'files' : 'file'}`]: (multipleFiles) ? fileInstances : _.first(fileInstances)
            };

            return store.dispatch('reports/createNewReport', { report, files }).then((uploadInfo) => {
              let completion = uploadInfo.completionPromise;
              if (completion != null) completion.then(() => {
                store.dispatch('reports/fetchReportIndex');
              });
              //TODO Could possibly upload multiple reports
              return { singleID: _.get(uploadInfo, ['key']) };
            })
            .then(({connectionID, singleID}) => {
              //Go to uploading reports (from connection) page or tracking page if index is null
              if (connectionID != null) {
                router.replace(`/health/analysis/reports/${connectionID}`);
              } else if (singleID != null) {
                router.replace(`/health/analysis/report/${singleID}`);
              }
            })
            .then(() => {
              //Set the currently selected day and month to the one of this report, to immediately scroll to the upload to see progress
              let localReportTime = getLocalTimeObject.value(report['timestamp']); //FIXME Only continue if date is valid? Try what happens with invalid date again! Otherwise go to today or let the store handle the error and go to today!
              store.dispatch('reports/updateSelectedMonth', localReportTime.format('MM.YYYY'));
              store.dispatch('reports/updateSelectedDay', localReportTime.format('DD.MM.YYYY'));
              store.dispatch('reports/updateSelectedTimespanDay', null);
              //TODO Reset filters to show the currently uploading one!!!
            })
            .catch((error) => {
              console.error(error);
            });
            
          } 
        });
        //TODO Show error if the report type can't be loaded?
    }

    const getLabelCurrentLocaleOrCapitalize = computed(() => {
      return getLabelCurrentLocaleOrCapitalizeFunction(i18n.locale.value);
    });

    return { 
      i18n,
      isVeterinarian,
      quickAnalysisOptions,
      statusTrackingOptionsWithInformation,
      userQuestionStages,
      userQuestionStageChoices,
      setQuestionStageRef,
      isQuestionStageDependencySatisfied,
      areAllQuestionsAnswered,
      getQuestionStageKeyWithDependency,
      selectUserQuestionChoice,
      reportTypeIndex,
      protocolHidingMode,
      updateProtocolHidingMode,
      hiddenEntryTypeStructures,
      selectedEntryTypes,
      isExaminationSelectionOpen,
      availableServiceTypes,
      servicesSelectionRef,
      selectedServices,
      toggleSelectDeselectAll,
      isSelectingServices,
      areServicesSelected,
      cancelEditingServices,
      toggleServiceEditing,
      isEditingServices,
      changedServicesExist,
      noValuePlaceholder,
      setEntryTypeHidden,
      calculateAgeFromBirthdate,
      getSimpleLocalDate,
      getSimpleLocalDateAndTime,
      ageYearFormat,
      ageDateFormat,
      translationPrefix,
      availableHorses,
      objectContainsNonNullValues,
      chooseSubject,
      selectedAnimal,
      editAnimal,
      availableStages,
      selectedStage,
      providedServices,
      updateServices,
      isAddServicePopupOpen,
      setAddServiceOpen,
      addServicePopupEventRef,
      addService,
      createInvoice,
      hexToHue,
      selectedEntryTypeCount,
      createReportTemplate,
      performQuickAnalysis,
      getLabelCurrentLocaleOrCapitalize,
      translateAttribute: editAnimalModal.translateAttribute,
      addStatus,
      openStatusOverview,
      addMetadataEntry,
      generateQR,
      faMarsAndVenus,
      faHashtag,
      faDna,
      faCakeCandles,
      faAddressCard,
      faStethoscope,
      faReceipt,
      faFileInvoice,
      faNotesMedical,
      faRectangleList,
      faUserDoctor,
      faImage,
      faNoteSticky,
      faCommentMedical,
      pencil,
      ellipsisHorizontal,
      arrowForward,
      gitCommit,
      close,
      save,
      arrowDown,
      squareOutline,
      checkboxOutline,
      add,
      filter,
      qrCode
    };
  }
}
</script>

<style>
/*popover styles cannot be scoped!*/
.add-service-popup {
  --width: 15em;
}
</style>

<style scoped>
.hidden-element {
  display: none;
}

.horse-image-large {
  position: relative;
  --max-height: 200px;
  max-height: var(--max-height);
  margin-bottom: 2px;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.horse-image-large > :deep(*) {
  height: 100%;
  width: 100%;
  max-height: var(--max-height);
  object-fit: contain;
  z-index: 200;
  filter: drop-shadow(0px 0px 20px var(--custom-color, var(--ion-color-primary)));
  -webkit-filter: drop-shadow(0px 0px 20px var(--custom-color, var(--ion-color-primary)));
}

.horse-image-large > :deep(.background) {
  object-fit: cover;
  position: absolute;
  filter: blur(8px) brightness(0.7);
  -webkit-filter: blur(8px) brightness(0.7);
  transform: scale(1.1); /* Remove white border around image */
}

.horse-color-large {
  height: 8px;
  width: 100%;
  background: var(--custom-color, var(--ion-color-primary));
}

.status-information {
  display: flex;
  flex-flow: column;
  position: relative;
  padding-top: 10px;
}

.horse-info-status ion-card-header,
.horse-info-status ion-card-content {
  display: flex;
  flex-flow: row;
  align-items: center;
}

.horse-info-status ion-avatar {
  flex-shrink: 0;
}

.horse-info-status ion-card-header {
  justify-content: space-between;
}

.horse-info-status .horse-info-label {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  row-gap: 10px;
}

.horse-info-status .horse-info-label > * {
  flex-shrink: 1;
  flex-grow: 1;
  flex-basis: 50%;
}

.horse-info-status .horse-info-label > * > span {
  min-width: 120px;
}

.animal-select {
  font-weight: bold;
  --custom-padding-start: 5px;
  color: var(--ion-color-primary-text);
}

.text-with-icon {
  display: flex;
  align-items: center;
}

.text-with-icon svg {
  min-width: 1.9em;
}

.horse-info-status .text-with-icon svg {
  min-width: 2.5em;
}

.text-with-icon .multiline {
  display: flex;
  flex-flow: column;
}

.text-with-icon .multiline span {
  border-left: 2px solid var(--ion-color-medium);
  padding-left: 4px;
  line-height: 1.5em;
  margin-left: -4px;
}

.text-with-icon .multiline span:only-of-type {
  border: none;
  line-height: unset;
  margin-left: 0px;
  padding-left: 0px;
}

.contact-link {
  text-decoration: none;
}

.animal-buttons {
  display: flex;
  column-gap: 1em;
  font-size: 16px;
}

.edit-button, .qr-button {
  height: 32px;
  width: 32px;
  --padding-start: 0px;
  --padding-end: 0px;
}

.edit-button {
  --color: var(--ion-color-primary-text);
}

.qr-button {
  --color: var(--ion-color-quaternary-text);
}

.more-info-container {
  width: 100%;
  flex-shrink: 0!important;
  flex-basis: 100%!important;
  flex-grow: 1!important;
}

.more-info-container {
  display: flex;
  padding-top: 20px;
}

.more-info {
  display: inline-block;
  text-transform: none;
  height: 2em;
  --background: var(--ion-color-medium-light);
  --color: var(--ion-color-medium-light-contrast);
}

#over-scroll {
  width: 100%;
  height: 100px;
}

ion-card, .outer-status-tracking-item {
  font-size: 20px;
}

ion-card-title {
  display: flex;
  align-items: center;
  color: var(--ion-color-primary-text);
  font-size: 1em!important;
}

.quick-analysis-card .logo {
  color: var(--ion-color-primary-text);
  margin-right: 0.5em;
  width: 1.25em;
  height: 1.25em;
}

.quick-analysis-card ion-card-content {
  font-size: 1em;
}

.quick-analysis-card ion-item {
  font-size: 1em;
  --detail-icon-color: var(--ion-color-primary-text);
  --detail-icon-opacity: 0.75;
  --padding-start: 1.75em;
}

.quick-analysis-card h1 {
  font-size: 0.9em;
  /* color: var(--ion-color-primary-text); */
}

.quick-analysis-card .media-icon {
  color: var(--ion-color-primary-text);
  font-size: 1em;
}

.quick-analysis-options, .quick-analysis-options ion-item {
  background-color: var(--ion-card-background, #fff);
  --background: var(--ion-card-background, #fff);
}

.quick-analysis-options ion-item:last-of-type {
  --border-width: 0px 0px 0px 0px;
}

.quick-analysis-options ion-label {
  white-space: normal;
}

.question-container {
  position: relative;
  margin-bottom: 15px;
}

.question-container::before {
  content: "";
  display: block;
  box-sizing: border-box;
/*   --margin: 10px;
  margin-left: var(--margin);
  margin-right: var(--margin);
  width: calc(100% - 2 * var(--margin));
  height: 20px; */
  margin: 0px 5px;
  border-top: 1px solid var(--ion-color-medium-light, gray);
  margin-bottom: 15px;
}

.ios ion-card {
  margin-top: 10px;
  margin-bottom: 10px;
}

ion-card-title {
  font-weight: 500;
}

.ios ion-card-title {
  font-size: 1.5em;
}

.ios ion-card-header ion-button {
  font-size: 1em;
}

.health-status-subtitle {
  text-transform: none;
  
}

.health-status-subtitle span {
  display: inline-block;
}

.health-status-subtitle h1 {
  
  font-size: 1.25em;
  color: var(--ion-color-step-850);
}

.health-status-subtitle h2 {
  font-weight: 400;
  font-size: 1em;
  color: var(--ion-color-step-750);
}

.question-header h1 {
  color: var(--ion-text-color);
}

.question-option-list, .question-option-list ion-item {
  background-color: var(--ion-card-background, #fff);
  --background: var(--ion-card-background, #fff);
}

.question-option-list ion-item:last-of-type {
  --border-width: 0px 0px 0px 0px;
  --inner-border-width: 0px 0px 0px 0px;
}

.question-option-list ion-label {
  white-space: normal;
}

hr.separator {
  height: 1px;
  background: var(--ion-color-medium-light, gray);
  margin: 5px 0 10px 0;
  z-index: 2;
  position: relative;
}

.continue-icon-hint-container {
  vertical-align: sub;
}

.continue-icon-hint {
  font-size: 1.5em;
}

.choose-stage-subtitle {
  text-transform: none;
  transition: opacity 0.25s;
  opacity: 1;
  margin-top: 10px;
}

.choose-stage-subtitle.hidden {
  opacity: 0;
}

.health-status-icon {
  width: 1.25em;
  color: var(--ion-color-primary-text);
  margin-right: 0.5em;
}

.stage-icon {
  width: 1.25em;
  color: var(--ion-color-primary-text);
  margin-right: 0.5em;
}

ion-list-header .stage-icon {
  margin-right: 5px;
}

.stage-icon.small {
  font-size: 1.25em;
}

.wrapping-title {
  padding: 5px 20px;
  font-weight: 500;
  font-size: 1.15em;
  color: var(--ion-color-dark-tint, gray);
}

.examination-card ion-card-content {
  padding-bottom: 0px;
}

.continue-report-icon-addition {
  position: absolute;
  font-size: 20px;
  margin-top: 16px;
  margin-left: 20px;
}

.metadata-add-button {
  font-size: 22px;
  --background: var(--ion-color-quaternary);
}

.metadata-add-button .metadata-add-icon {
  margin-right: 10px;
  margin-top: 16px;
}

.metadata-add-icon-addition {
  position: absolute;
  font-size: 22px;
  margin-bottom: 22px;
  margin-left: 14px;
  transform: scaleX(-1);
}

.continue-button {
  font-size: 20px;
}

.continue-button .examination-icon {
  margin-right: 10px;
  margin-bottom: 10px;
}

.title-icon {
  display: block;
  flex-shrink: 0;
}

.select-protocol-title {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 5px 16px;
}

.ios .select-protocol-title {
  padding: 5px 20px;
}

.select-protocol-icon {
  width: 1.25em;
  margin-right: 0.5em;
}

.provided-services-list-card, .examination-selection, .examination-card {
  /* Overflow clip makes the sticky child skip to the next scrollable ancestor.
  All other properties besides visible attach the stickiness to this container! */
  overflow: clip;
  --custom-overflow: clip;
}

.examination-selection {
  --color: var(--ion-color-primary-text);
  color: var(--color);
}

.provided-services-list-card ion-card-header {
  display: flex;
  justify-content: space-between;
}

.sticky-header-container {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: var(--ion-card-background, #fff);
  --background: var(--ion-card-background, #fff);
}

.provided-services-list-card ion-card-header ion-card-title {
  min-width: 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  flex-shrink: 1;
}

.provided-services-list-card .services-edit-buttons ion-button {
  height: 32px;
  width: 32px;
  --padding-start: 0px;
  --padding-end: 0px;
  --border-width: 2px;
}

.provided-services-list-card .services-edit-buttons #add-services-button {
  --border-width: 0px;
  margin-inline-end: 10px;
}

.provided-services-list-card .cancel-services-edit-button {
  --custom-size: 32px!important;
  margin-block: 0px;
  margin-inline: 10px;
}

.provided-services-list-card .cancel-services-edit-button ion-icon {
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.provided-services-list-card ion-card-header .services-icon {
  font-size: 0.8em;
  padding-inline-end: 10px;
}

.provided-services-list-card {
  background-color: var(--ion-card-background, #fff);
  --background: var(--ion-card-background, #fff);
}

.provided-services-list-card ion-button {
  text-transform: none;
}

.invoice-creation {
  display: flex;
  font-size: 16px;
  align-items: center;
}

.invoice-selection-icon {
  padding-inline-start: 10px;
  padding-inline-end: 10px;
  width: 50px;
  display: flex;
  justify-content: center;
  font-size: 1.1em;
}

.ios .invoice-selection-icon {
  font-size: 1.6em;
}

/* Split button with two icons */
.multi-selection-button {
  margin: 9px 10px 9px 0px;
  --padding-start: 0px;
  --padding-end: 0px;
  font-size: 0.8em;
  height: 42px;
  width: 42px;
  --border-width: 2px;
  --border-style: solid;
  --border-color: var(--ion-color-medium, #92949c);
  --background: var(--ion-color-medium, #92949c);
  --color: var(--ion-color-medium-contrast, #000000);
  --background-activated: var(--ion-color-medium, #92949c);
  --background-focused: var(--ion-color-medium, #92949c);
  --background-hover: var(--ion-color-medium, #92949c);
}

.multi-selection-button.button-outline {
  --background: transparent;
  --color: var(--ion-color-medium, #92949c);
  --background-activated: transparent;
  --background-focused: var(--ion-color-medium, #92949c);
  --background-hover: transparent;
}

.multi-selection-button > * {
  width: 1em;
}

.multi-selection-button > .all {
  position: absolute;
  margin-right: 18px;
  margin-bottom: 16px;
}

.multi-selection-button > .none {
  position: absolute;
  margin-left: 18px;
  margin-top: 16px;
}

.multi-selection-button > .angled-separator {
  width: 3em;
  transform: rotate(-45deg);
  height: 1px;
  background: currentColor;
}

.create-invoice-button {
  --padding-start: 5px;
  --padding-end: 5px;
  margin-inline-start: 15px;
}
.create-invoice-button > * {
  margin-inline: 5px;
}

.create-invoice-icon {
  margin-inline-start: 10px;
  font-size: 1.5em;
}

.add-service-header {
  color: var(--ion-color-medium);

  font-size: 1.2em;
  font-weight: bold;
}

.ios .add-service-header {
  padding-bottom: 20px;
}

.add-custom-service {
  font-style: italic;
}

.service-descriptor b {
  font-weight: 600;
}

.status-tracking-icon {
  width: 1.25em;
  color: var(--ion-color-primary-text);
  margin-right: 0.5em;
}

.outer-status-tracking-item {
  margin-inline-start: 1.25em;
  --padding-start: 0px;
  --padding-end: 0px;
  --inner-padding-end: 0px;
}

.inner-status-tracking-item {
  flex-grow: 1;
  margin-bottom: 1px;
  --padding-start: 0.5em;
}

.add-status-button {
  font-size: 0.75em;
  width: 2em;
  height: 2em;
  --padding-start: 6px;
  --padding-end: 6px;
  margin-inline-start: 6px;
  margin-inline-end: 6px;
}

.status-tracking-options, .status-tracking-options ion-item {
  background-color: var(--ion-card-background, #fff);
  --background: var(--ion-card-background, #fff);
}

.status-tracking-options > ion-item:last-of-type {
  --border-width: 0px 0px 0px 0px;
}

.status-tracking-options ion-label {
  white-space: normal;
}

.status-tracking-options ion-label > .status-descriptor {
  /* color: var(--ion-color-primary-text); */
  font-weight: bold;
}

.status-tracking-options ion-label > * {
  color: var(--ion-text-color, black);
}

.status-descriptor {
  display: flex;
  align-items: center;
}

.status-descriptor svg {
  min-width: 1.5em;
  margin-right: 0.4em;
}

.status-descriptor ion-badge {
  --background: var(--ion-color-primary);
  margin-left: 5px;
  vertical-align: sub;
}

.status-descriptor ion-badge.empty {
  --background: var(--ion-color-medium-light);
  --color: var(--ion-color-primary-text);
}

.status-details span {
  white-space: nowrap;
}

.status-details span:not(:last-of-type)::after {
  content: "|";
  margin-inline: 5px;
}

.status-latest-timestamp {
  width: fit-content;
  padding-bottom: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
  border-bottom: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-250, #c8c7cc)));
}
</style>
