<template id="status-overview-modal">
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ currentAttributes.title }}</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="closeModal()">{{ i18n.$t('default_interaction.close') }}</ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <ion-item class="header" lines="full" v-if="currentAnimal != null">
          <ion-label>
            <h2>
              <b>{{ currentAnimal.name }}</b>
              <font-awesome-icon :icon="currentAttributes.icon" />
              <ion-badge :class="(currentStatuses.length <= 0) ? 'empty' : undefined">{{ currentStatuses.length || 0 }}</ion-badge>             
            </h2>
            <h3 v-if="currentAnimal.unique_identifier != null"># {{ currentAnimal.unique_identifier }}</h3>
          </ion-label>
        </ion-item>
        <template v-if="currentStatuses.length > 0">
          <ion-item v-for="(status, statusIndex) in currentStatuses" :key="status.id" lines="full" :class="['status-tracking-item', (statusIndex == 0) ? 'first' : undefined]">
            <ion-label>
              <!--   -->
              <p class="status-timestamp" v-if="status.timestamp != null"><b>{{ getSimpleLocalDateAndTime(status.timestamp) }}</b> <ion-badge v-if="statusIndex == 0">{{ i18n.$t('analysis.status-tracking.newest_entry') }}</ion-badge></p>
                <p v-if="status.details != null" class="status-details">
                  <template v-for="detail in currentAttributes.details" :key="detail.descriptor">
                    <span v-if="detail.descriptor in status.details" >
                      <b>{{ getLabelCurrentLocaleOrCapitalize(detail, detail.descriptor) }}</b> &centerdot; {{ status.details[detail.descriptor] }} {{ detail.unit }}
                    </span>
                  </template>
                  <span v-if="status.images != null" >
                    <font-awesome-icon :icon="faImage" /> &centerdot; {{ status.images.length }}
                  </span>
                </p> 
            </ion-label>

          </ion-item>
        </template>
        <ion-item v-else lines="full">
          <ion-label>
            <p >{{ i18n.$t('analysis.status-tracking.no_entries') }}</p>
          </ion-label>
        </ion-item>
      </ion-list>
      <!-- TODO Add option to see more in the Tracking page with the filter correctly pre-applied? Show all here with infinite list, and also give option to go that is always visible!! -->
    </ion-content>
  </ion-page>
</template>

<script>

import { IonPage, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonContent, IonList, IonItem, IonBadge, IonLabel, modalController } from '@ionic/vue';
import { computed, defineComponent, watch, ref } from 'vue';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import { useI18n } from "@/utils/i18n";

import { useStore } from 'vuex';

import { useDayjs } from '@/utils/dayjs';
import { getLabelCurrentLocaleOrCapitalizeFunction } from '@/utils/animals';

import { statusTrackingOptions } from '@/config/analysis';

import _ from 'lodash';

const StatusOverviewModal = defineComponent({
  name: 'StatusOverviewModal',
  components: { IonPage, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonContent, IonList, IonItem, IonBadge, IonLabel, FontAwesomeIcon },
  props: {
    'currentAnimal': Object,
    'currentAnimalPersonalInfoId': String,
    'type': String
  },
  setup(props) {
    const i18n = useI18n();

    const store = useStore();

    const { getSimpleLocalDateAndTime } = useDayjs();

    const closeModal = function(){
      modalController.dismiss();
    }

    const currentPersonalInfoId = computed(() => props.currentAnimalPersonalInfoId);

    const currentType = computed(() => props.type);

    const currentAttributes = computed(() => {
      let type = props.type;
      let locale = i18n.locale.value;

      let option = _.find(statusTrackingOptions, { descriptor: type });
      return {
        title: _.get(option, ['label', locale]),
        icon: _.get(option, ['icon']),
        details: _.get(option, ['details'])
      };
    });
    
    //TODO CHeck sorting!
    const currentStatuses = ref([]);
    const statusGetterAsyncFunction = computed(() => store.getters['horses/getStatusesForHorseWithTypeAsync']);

    watch([statusGetterAsyncFunction, currentPersonalInfoId, currentType], ([newGetterFunction, newInfoId, newType]) => {
      newGetterFunction(newInfoId, newType).then((newStatuses) => currentStatuses.value = newStatuses);
    }, { immediate: true });

    const getLabelCurrentLocaleOrCapitalize = computed(() => {
      return getLabelCurrentLocaleOrCapitalizeFunction(i18n.locale.value);
    });

    return { i18n, closeModal, currentAttributes, currentStatuses, getSimpleLocalDateAndTime, getLabelCurrentLocaleOrCapitalize };
  }
});

export async function openStatusOverviewModal(component, currentAnimal, currentAnimalPersonalInfoId, type){
  if (component != null && currentAnimal != null && currentAnimalPersonalInfoId != null && type != null) {
    const modal = await modalController
      .create({
        component,
        componentProps: {
          currentAnimal,
          currentAnimalPersonalInfoId,
          type
        }
      })
    modal.present();
    return modal.onWillDismiss();
  }
}

export default StatusOverviewModal;
</script>

<style scoped>
ion-item ion-label * {
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

.header {
  text-align: center;
}

.header h2 {
  font-size: 1.2em;
  display: flex;
  align-items: center;
  justify-content: center;
}

ion-item ion-label {
  white-space: normal;
}

ion-label > * {
  color: var(--ion-text-color, black);
}

.header h2 svg {
  color: var(--ion-color-primary-text);
  margin-inline: 0.5em;
}

.header h2 ion-badge {
  --background: var(--ion-color-primary-text);
  vertical-align: sub;
}

.header h2 ion-badge.empty {
  --background: var(--ion-color-medium-light);
  --color: var(--ion-color-primary-text);
}

.status-details span {
  white-space: nowrap;
}

.status-details span:not(:last-of-type)::after {
  content: "|";
  margin-inline: 5px;
}

.status-timestamp {
  display: flex;
  align-items: center;
  column-gap: 10px;
  width: fit-content;
  padding-bottom: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
  border-bottom: 1px solid var(--ion-item-border-color, var(--ion-border-color, var(--ion-color-step-250, #c8c7cc)));
}

.status-tracking-item.first .status-timestamp {
  color: var(--ion-color-primary-text);
}
</style>