import { faCalendarDay, faImage } from '@fortawesome/free-solid-svg-icons';
import { computed } from 'vue';

import { useStore } from 'vuex';
import { useI18n } from "@/utils/i18n";
import { useDayjs } from '@/utils/dayjs';

import { localErrorToast } from '@/utils/error';

import { openEditModal, default as editModalComponent } from '@/components/EditModal.vue';

import { statusTrackingOptions } from '@/config/analysis';

import _ from 'lodash';

export function useEditStatusModal() {
  const i18n = useI18n();

  const { dayjs } = useDayjs();

  const store = useStore();

  //Defines the order of the available fields and additional metadata
  const formTemplate = computed(() => {
    return function(type) {
      if (type == null) return null;

      let option = _.find(statusTrackingOptions, { descriptor: type });

      if (option == null) return null;

      let locale = i18n.locale.value;

      return [
        {
          descriptor: 'general',
          isRootAttribute: true,
          fields: [
            { descriptor: 'timestamp', type: 'datetime', required: true, icon: faCalendarDay, iconIsFontAwesome: true },
            { descriptor: 'images', type: 'images', icon: faImage, iconIsFontAwesome: true }
          ],
        },
        {
          descriptor: 'details',
          label: _.get(option, ['label', locale], undefined),
          fields: _.map(option.details, (detailField) => {
            return {
              ...detailField,
              label: _.get(detailField, ['label', locale], undefined)
            }
          })
        }
      ]
    }
  });

  const presetValues = computed(() => {
    return {
      timestamp: dayjs.utc().toISOString()
    }
  })

  const open = async function(status, horse, type, viewMode = false) {
    let template = formTemplate.value(type);
    if (template == null) return Promise.resolve();

    return openEditModal(editModalComponent, {
      formTemplate: template,
      automaticLocalizationPrefix: 'status.attributes',
      viewMode,
      createTitle: i18n.$t('status.edit.title.create'),
      editTitle: i18n.$t('status.edit.title.edit'),
      viewTitle: i18n.$t('status.edit.title.view'),
      deleteText: i18n.$t('status.edit.delete.title'),
      deleteQuestion: i18n.$t('status.edit.delete.question'),
      deleteConfirmation: i18n.$t('status.edit.delete.confirmation'),
      deleteWaitTimeSeconds: 0,
      existingValues: status,
      presetValues: presetValues.value,
      keepEmptyRootAttributes: true,
      enableDelete: true
    }).then(async ({action, data, files = {}}) => {
      if (action !== 'delete' && (data == null || Object.keys(data).length < 1) && (files == null || Object.keys(files).length < 1)) return;
      let statusObject = (data == null) ? {} : {...data, type, horse}; //Create a shallow copy to remove properties and add type

      if (action === 'update' && status != null && status.id != null) {
        return store.dispatch('horses/updateStatus', {id: status.id, status: statusObject, files}).then((uploadStatus) => uploadStatus.key); //TODO Try uploading multiple files
      } else if (action === 'create') {
        return store.dispatch('horses/addStatus', {status: statusObject, files}).then((uploadStatus) => uploadStatus.key);
      } else {
        throw 'No valid action';
      }
    }).catch((error) => {
      console.error(error);
      localErrorToast(i18n, error);
    });
  }

  return { open };
}