<template>
  <div class="outer-container" :style="`--stage-count: ${stageOptions.length}`">
    <div class="scroll-view">
      <div class="container" v-if="stageOptions != null && stageOptions.length">
        <div v-for="(stage, index) of stageOptions" :key="index" :class="['description', getEvenOddClass(index), getSelectedStageClass(stage.descriptor)]">
          <span>{{ stage.localizedDescriptor }}</span>
          <ion-icon class="stage-graphic" :icon="((index % 2) == 0) ? caretDown : caretUp" :style="`--state-color: var(--${getStageIndicator(stage.descriptor).color});`"></ion-icon>
        </div>
        <div class="indicator spacer">
          <font-awesome-icon :class="enableSelect ? undefined : 'hidden'" :icon="faHandPointer" />
        </div>
        <div 
          v-for="(stage, index) of stageOptions" 
          :key="index" :class="['indicator', getSelectedStageClass(stage.descriptor), getStageIndicator(stage.descriptor).class, (enableSelect ? 'selectable' : '')]"
          :style="getStageIndicator(stage.descriptor).color"
          >
          <div class="icon-button" role="button" @click="selectStage(stage.descriptor)">
            <div class="icon">
              <div class="background"></div>
              <div class="foreground">
                <font-awesome-icon class="stage-graphic" :icon="getStageIndicator(stage.descriptor).icon" />
              </div>
            </div>
          </div>
          <div v-if="index < (stageOptions.length - 1)" class="connecting-line stage-graphic"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { IonIcon } from '@ionic/vue';
import { defineComponent, onMounted, ref, watch, computed } from 'vue';

import { caretUp, caretDown, arrowForward } from 'ionicons/icons';

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCircle as faCircleSolid, faCheck, faHandPointer } from '@fortawesome/free-solid-svg-icons';
import { faCircleDot, faCircle } from '@fortawesome/free-regular-svg-icons';

import { useI18n } from "@/utils/i18n";

export default defineComponent({
  name: 'ExaminationStage',
  components: { IonIcon, FontAwesomeIcon },
  props: {
    stageOptions: Array,
    completedStages: Array,
    selectedStage: String,
    enableSelect: {
      type: Boolean,
      default: true
    },
    preSelectFirstIncompleteStage: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update:selectedStage'],
  setup(props, { emit }) {
    const i18n = useI18n();

    const currentSelectedStage = ref(null);

    watch((currentSelectedStage), (newSelectedStage) => {
      emit('update:selectedStage', newSelectedStage);
    })

    const getStageIndicator = function(stage) {
      let indicator = {};

      if (Array.isArray(props.completedStages) && props.completedStages.includes(stage)) {
        indicator.color = 'ion-color-success';
        indicator.class = 'completed';
        if (stage == currentSelectedStage.value) {
          indicator.icon = faCircleDot;
        } else {
          indicator.icon = faCircleSolid;
        }
      } else {
        if (stage == currentSelectedStage.value) {
          indicator.icon = faCircleDot;
        } else {
          indicator.icon = faCircle;
        }
      }

      //Convert color to css variable if set
      if (indicator.color != null) indicator.color = `--state-color: var(--${indicator.color});`;

      return indicator;
    }

    const getEvenOddClass = function(index) {
      if ((index % 2) == 0) {
        return 'even';
      }
      return 'odd';
    }

    const getSelectedStageClass = computed(() => {
      return function(stage) {
        if (stage == currentSelectedStage.value) {
          return 'selected';
        }
        return undefined;
      }
    });

    const selectStage = function(stage) {
      if (props.enableSelect) {
        currentSelectedStage.value = stage;
      }
    }

    onMounted(() => {
      if (props.selectedStage != null) currentSelectedStage.value = props.selectedStage;
      else if (props.preSelectFirstIncompleteStage && Array.isArray(props.stageOptions) && Array.isArray(props.completedStages)) {
        for (let stage of props.stageOptions) {
          if (!(props.completedStages.includes(stage.descriptor))) {
            currentSelectedStage.value = stage.descriptor;
            return;
          }
        }
      }
    });

    return { i18n, faCircleDot, faCircle, faCircleSolid, caretUp, caretDown, faCheck, faHandPointer, arrowForward, getStageIndicator, getEvenOddClass, getSelectedStageClass, selectStage };
  }
});
</script>

<style scoped>
.outer-container {
  position: relative;
  display: inline-block;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.scroll-view {
  overflow-x: scroll;
  width: 100%;
  max-width: calc(var(--stage-count, 1) * 150px);
  padding-top: 10px;
  padding-bottom: 20px;
  
}

.outer-container:after {
  content: " ";
  pointer-events: none;
  box-shadow: inset 0 0 10px 5px var(--background, #000);
  z-index: 1000;
  position: absolute;
  top: -15px;
  bottom: -15px;
  left: 0;
  right: 0;
}

.container {
  display: grid;
  grid-template-rows: 1fr 2em 1fr;
  grid-template-columns: repeat(calc(1 + var(--stage-count, 1)), 1fr);
  min-width: calc(var(--stage-count, 1) * 100px);
}
.description.even {
  grid-row: 1;
  justify-content: end;
}
.description.odd {
  grid-row: 3;
  justify-content: start;
  flex-direction: column-reverse;
}
.description {
  display: flex;
  flex-flow: column;
  align-items: center;
  grid-column: span 2;
  text-align: center;
  max-width: 100%;
  padding: 0px 15%;
  font-size: clamp(0.8em, 3vw, 1em);
  color: var(--ion-color-dark-tint);
}

.description:first-of-type {
  grid-column-start: 1;
  grid-column-end: 3;
}

.description:nth-of-type(2) {
  grid-column-start: 2;
  grid-column-end: 4;
}

.description span {
  display: block;
}

.indicator {
  position: relative;
  display: flex;
  align-items: center;
  font-size: clamp(1.2em, 4vw, 1.5em);
  grid-row: 2;
  /* font-size: 1.5em; */
}

.indicator.spacer {
  justify-content: center;
}

.indicator.spacer > * {
  transition: opacity 0.25s;
  opacity: 1;
}

.indicator.spacer > .hidden {
  opacity: 0;
}

.connecting-line {
  width: 100%;
  position: absolute;
  background: currentcolor;
  height: max(.15em, 1px);
}

.indicator .icon-button {
  transition: font-size .1s ease-in;
  --button-padding-width: 20%;
  --button-padding-height: 25%;
  padding: var(--button-padding-height, 0px) var(--button-padding-width, 0px);
  margin-left: calc(-0.5em - var(--button-padding-width, 0px));
}

.indicator.selectable .icon-button {
  pointer-events: all;
  cursor: pointer;
}

.icon {
  position: relative;
}

/* Set background to cover the lines in hollow icons */
.icon .background { 
  position: absolute;
  width: 90%;
  height: 90%;
  background: var(--background, #fff);;
  z-index: 10;
  box-sizing: content-box;
  margin: 5%;
}

.icon .foreground {
  z-index: 20;
  position: relative;
}

.indicator.selectable .icon-button:hover,
.indicator.selected .icon-button {
  font-size: 1.3em;
}

.stage-graphic {
  color: var(--state-color, var(--ion-color-dark-tint));
  filter: contrast(0.8);
}

</style>
