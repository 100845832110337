import { camera } from 'ionicons/icons';
import { faHouseMedicalFlag, faWeightScale, faSyringe, faHandHoldingMedical, faClipboardCheck, faBarcode, faRulerVertical } from '@fortawesome/free-solid-svg-icons';

export const quickAnalysisOptions = [
  {
    "key": "eye",
    "icon": camera,
    "entryTypeIdentifier": "nostage_nosub_2_ki-analyse-pferdeauge",
    "fieldKey": "uncategorized->Foto Pferdeauge"
  }
];

export const statusTrackingOptions = [
  {
    "descriptor": "visit_reason",
    "icon": faHouseMedicalFlag,
    "pro_only": true,
    "label": { "de": "Vorstellungsgrund", "en": "Reason for visit" },
    "details": [
      { "descriptor": "reason", "type": "text", "required": true, "icon": faHouseMedicalFlag, "iconIsFontAwesome": true, "label": { "de": "Vorstellungsgrund", "en": "Reason for visit" } }
    ]
  },
  {
    "descriptor": "weight",
    "icon": faWeightScale,
    "label": { "de": "Gewicht", "en": "Weight" },
    "details": [
      { "descriptor": "weight", "type": "decimal", "required": true, "icon": faWeightScale, "iconIsFontAwesome": true, "label": { "de": "Gewicht", "en": "Weight" }, "unit": "kg" }
    ]
  },
  {
    "descriptor": "vaccine",
    "icon": faSyringe,
    "label": { "de": "Impfungen", "en": "Vaccinations" },
    "details": [
      { "descriptor": "type",   "type": "text", "required": true, "icon": faSyringe, "iconIsFontAwesome": true, "label": { "de": "Impfstoff", "en": "Vaccine" } },
      { "descriptor": "batch",  "type": "text", "required": false, "icon": faBarcode, "iconIsFontAwesome": true, "label": { "de": "Charge",    "en": "Batch" } },
      { "descriptor": "amount", "type": "text", "required": false, "icon": faRulerVertical, "iconIsFontAwesome": true, "label": { "de": "Menge",     "en": "Amount" } }
    ]
  },
  {
    "descriptor": "surgery",
    "icon": faHandHoldingMedical,
    "label": { "de": "Operationen", "en": "Surgeries" },
    "details": [
      { "descriptor": "type", "type": "text", "required": true, "icon": faHandHoldingMedical, "iconIsFontAwesome": true, "label": { "de": "Art der Operation", "en": "Type of surgery" } }
    ]
  },
  {
    "descriptor": "diagnosis",
    "icon": faClipboardCheck,
    "pro_only": true,
    "label": { "de": "Diagnosen", "en": "Diagnoses" },
    "details": [
      { "descriptor": "description", "type": "text", "required": true, "icon": faClipboardCheck, "iconIsFontAwesome": true, "label": { "de": "Beschreibung der Diagnose", "en": "Description of the diagnosis" } }
    ]
  }
];
  
export const userQuestionStages = [
  {
    "key": "examination_stage",
    "options": [
      { "key": "prevention" },
      { "key": "pre-examination"},
      { "key": "aftercare" }
    ],
    "multiple": false
  },
  {
    "key": "main_category",
    "dependsOn": "examination_stage",
    "options": [
      { "key": "general" },
      { "key": "eye"},
      { "key": "lameness"},
      { "key": "lungs"},
      { "key": "colic"}
    ],
    "multiple": true
  }
];

//TODO Use correct protocols
export const questionEntryTypes = {
  "prevention": {
    "general": "allgemeine_dokumentation_auge_2_vorbericht",
    "eye": "allgemeine_dokumentation_auge_2_vorbericht",
    "lameness": "allgemeine_dokumentation_auge_2_vorbericht",
    "lungs": "allgemeine_dokumentation_auge_2_vorbericht",
    "colic": "allgemeine_dokumentation_auge_2_vorbericht"
  },
  "pre-examination": {
    "general": "allgemeine_dokumentation_auge_2_vorbericht",
    "eye": "allgemeine_dokumentation_auge_2_vorbericht",
    "lameness": "allgemeine_dokumentation_auge_2_vorbericht",
    "lungs": "allgemeine_dokumentation_auge_2_vorbericht",
    "colic": "allgemeine_dokumentation_auge_2_vorbericht"
  },
  "aftercare": {
    "general": "allgemeine_dokumentation_auge_2_vorbericht",
    "eye": "allgemeine_dokumentation_auge_2_vorbericht",
    "lameness": "allgemeine_dokumentation_auge_2_vorbericht",
    "lungs": "allgemeine_dokumentation_auge_2_vorbericht",
    "colic": "allgemeine_dokumentation_auge_2_vorbericht"
  }
}