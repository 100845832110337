import { faCommentMedical, faFilePen, faVoicemail } from '@fortawesome/free-solid-svg-icons';
import { computed } from 'vue';

//import { useStore } from 'vuex';
import { useI18n } from "@/utils/i18n";
import { useDayjs } from '@/utils/dayjs';

import { localErrorToast } from '@/utils/error';

import { openEditModal, default as editModalComponent } from '@/components/EditModal.vue';

//import _ from 'lodash';

export function useEditMetadataModal() {
  const i18n = useI18n();

  const { dayjs } = useDayjs();

  //const store = useStore();

  //Defines the order of the available fields and additional metadata
  const formTemplate = computed(() => {
    return [
      {
        descriptor: 'general',
        isRootAttribute: true,
        fields: [
          { descriptor: 'type', type: 'bool', required: true, icon: faCommentMedical, iconIsFontAwesome: true }
        ],
      },
      {
        descriptor: 'notes',
        isRootAttribute: true,
        fields: [
          { descriptor: 'voice_notes', type: 'audios', icon: faVoicemail, iconIsFontAwesome: true },
          { descriptor: 'content', type: 'text', required: true, icon: faFilePen, iconIsFontAwesome: true }
        ],
      }
    ]
  });

  const presetValues = computed(() => {
    return {
      type: false
    }
  })

  const open = async function(metadata, animalInfoId, viewMode = false) {
    let now = dayjs.utc().toISOString();

    return openEditModal(editModalComponent, {
      formTemplate: formTemplate.value,
      automaticLocalizationPrefix: 'metadata.attributes',
      viewMode,
      createTitle: i18n.$t('metadata.edit.title.create'),
      editTitle: i18n.$t('metadata.edit.title.edit'),
      viewTitle: i18n.$t('metadata.edit.title.view'),
      deleteText: i18n.$t('metadata.edit.delete.title'),
      deleteQuestion: i18n.$t('metadata.edit.delete.question'),
      deleteConfirmation: i18n.$t('metadata.edit.delete.confirmation'),
      deleteWaitTimeSeconds: 0,
      existingValues: metadata,
      presetValues: presetValues.value,
      keepEmptyRootAttributes: true,
      enableDelete: true
    }).then(async ({action, data, /* files = {} */}) => {
      if (action !== 'delete' && (data == null || Object.keys(data).length < 1)/* && (files == null || Object.keys(files).length < 1) */) return;
      let metadataObject = (data == null) ? {} : {
        ...data,
        timestamp: now,
        personal_horse_info: animalInfoId,
        type: (data.type) ? 'conversation' : 'note' //TODO For now only allow two types: Notes and Conversation Notes. Encoded as boolean
      }; //Create a shallow copy to remove properties and add type

      console.log(metadataObject);

      /* if (action === 'update' && metadata != null && metadata.id != null) {
        return store.dispatch('horses/updateMetadata', {id: metadata.id, metadata: metadataObject, files}).then((uploadStatus) => uploadStatus.key); //TODO Try uploading multiple files
      } else if (action === 'create') {
        return store.dispatch('horses/addMetadata', {metadata: metadataObject, files}).then((uploadStatus) => uploadStatus.key);
      } else {
        throw 'No valid action';
      } */
    }).catch((error) => {
      console.error(error);
      localErrorToast(i18n, error);
    });
  }

  return { open };
}