<template>
  <template v-for="(subCategory, subCategoryIndex) of orderedEntryTypes['sub_categories']" :key="subCategoryIndex">
    <div v-if="isSubCategoryVisible(subCategory['descriptor'])" class="sub-category-container list-level-1">
      <ion-item-divider v-if="subCategory['section'] == true && isSubCategorySectionVisible(subCategory['descriptor'])">{{ subCategory['localizedDescriptor'] }}</ion-item-divider>
      <CollapsibleList v-else-if="subCategory['section'] != true" :key="subCategory['descriptor']"
        :primaryIndicator="(selectedSubCategoryCounts != null && subCategory['descriptor'] in selectedSubCategoryCounts) ? selectedSubCategoryCounts[subCategory['descriptor']] : null"
        :primaryIcon="primaryIcon"
        :tertiaryIndicator="visibleEntryTypeCount(subCategory['descriptor'])"
        :tertiaryIcon="tertiaryIcon"
        :showHeader="!(isSubCategoryUndefined(subCategory))"
        :open="isSubCategoryUndefined(subCategory) || open"
        class="sub-category-list"> <!-- By default, only make list collapsible, if subCategory is defined (descriptor is valid) -->
        <template v-slot:label>
          <ion-label class="sub-category-label">{{ subCategory['localizedDescriptor'] }}</ion-label>
        </template>
        
        <div class="entry-type-container list-level-2" v-for="(entryType, entryTypeIndex) of subCategory['entry_types']" :key="entryTypeIndex">
          <ion-item-divider v-if="entryType['section'] == true && isSectionVisible(subCategory['descriptor'], entryType['descriptor'])">
            <ion-icon class="section-matching-indicator" :icon="tertiaryIcon" v-if="isSectionMatched(subCategory['descriptor'], entryType['descriptor'])"></ion-icon>
            {{ entryType['localizedDescriptor'] }}
          </ion-item-divider>
          <ion-item v-else-if="entryType['section'] != true && isEntryTypeVisible(subCategory['descriptor'], entryType['id'])" lines="full" :key="entryType['id']" :class="hidingMode ? 'hiding-mode' : ''">
              <ion-icon class="matching-indicator" :icon="tertiaryIcon" v-if="isEntryTypeMatched(subCategory['descriptor'], entryType['id'])" slot="start"></ion-icon>
              <div class="entry-type-label-container">
                <ion-label class="entry-type-label">{{ entryType['localizedDescriptor'] }} <ion-icon class="cache-indicator" v-if="entryType['cached']" :icon="cloudDone"></ion-icon></ion-label>
                <span class="entry-type-main-category" v-if="entryType['main_category'] != null">{{ getLocalizedMainCategory(entryType['main_category']) }}</span>
              </div>
              <ion-checkbox v-if="!hidingMode" slot="end" @ionChange="selectEntryType(entryType['id'], entryType['main_category'], subCategory['descriptor'], $event.target.checked)" :checked="isEntryTypeSelected(entryType['id'], entryType['main_category'], subCategory['descriptor'])"></ion-checkbox>
              <ion-button v-else class="hide-button" slot="end" color="medium" @click="toggleEntryTypeHidden(entryType['id'], entryType['main_category'], subCategory['descriptor'], entryType['descriptor'])">
                <ion-icon slot="icon-only" :icon="treatAsHidden ? eye : eyeOff"></ion-icon>
              </ion-button>
          </ion-item>
        </div>
      </CollapsibleList>
      <hr class="list-separator">
    </div>
  </template>
</template>

<script>
import { IonItem, IonItemDivider, IonLabel, IonCheckbox, IonIcon, IonButton } from '@ionic/vue';
import { computed, ref, watch } from 'vue';

import { eye, eyeOff, cloudDone } from 'ionicons/icons';

import CollapsibleList from '@/components/CollapsibleList.vue';

import _ from 'lodash';

export default  {
  name: 'EntryTypeSelection',
  components: { IonItem, IonItemDivider, IonLabel, IonCheckbox, IonIcon, IonButton, CollapsibleList },
  props: {
    stageDescriptor: String,
    orderedEntryTypes: Object,
    selectedEntryTypes: Array,
    selectedSubCategoryCounts: Object,
    foundEntryTypes: Object,
    primaryIcon: [Object, String],
    tertiaryIcon: [Object, String],
    hidingMode: Boolean,
    treatAsHidden: {
      type: Boolean,
      default: false
    },
    open: Boolean,
    localizedMainCategories: Object
  },
  emits: ['update:selectedEntryTypes', 'setEntryTypeHidden'],
  setup(props, { emit }){
    const selectedEntryTypesProp = computed(() => props.selectedEntryTypes);
    const foundEntryTypesProp = computed(() => props.foundEntryTypes);

    const selectedEntryTypesRef = ref(selectedEntryTypesProp.value);
    const foundEntryTypesRef = ref(foundEntryTypesProp.value);

    watch((selectedEntryTypesRef), (newSelectedTypes) => {
      emit('update:selectedEntryTypes', newSelectedTypes);
    });

    watch((selectedEntryTypesProp), (newSelectedTypes) => {
      selectedEntryTypesRef.value = newSelectedTypes;
    });

    watch((foundEntryTypesProp), (newFoundTypes) => {
      foundEntryTypesRef.value = newFoundTypes;
    });

    const isEntryTypeSelected = computed(() => {
      return (entryTypeId, mainCategory, subCategoryDescriptor) => {
        let entryTypeStructure = {main_category: mainCategory, stage: props.stageDescriptor, sub_category: subCategoryDescriptor, id: entryTypeId};

        return (Array.isArray(selectedEntryTypesRef.value) && (_.intersectionWith(selectedEntryTypesRef.value, [entryTypeStructure], _.isEqual).length > 0));
      }
    })

    const isSubCategorySectionVisible = computed(() => {
      return (sectionDescriptor) => {
        if (foundEntryTypesRef.value == null || !(Array.isArray(foundEntryTypesRef.value['sections_with_matches']))) return true; //No filter always means it's visible
        return (foundEntryTypesRef.value['sections_with_matches'].includes(sectionDescriptor));
      }
    })

    const isSubCategoryVisible = computed(() => {
      return (subCategoryDescriptor) => {
        if (foundEntryTypesRef.value == null || foundEntryTypesRef.value['sub_categories'] == null) return true; //No filter always means it's visible
        return (subCategoryDescriptor in foundEntryTypesRef.value['sub_categories']);
      }
    })

    const isSectionVisible = computed(() => {
      return (subCategoryDescriptor, sectionDescriptor) => {
        if (foundEntryTypesRef.value == null || foundEntryTypesRef.value['sub_categories'] == null) return true; //No filter always means it's visible
        return (foundEntryTypesRef.value['sub_categories'][subCategoryDescriptor] != null && 
                Array.isArray(foundEntryTypesRef.value['sub_categories'][subCategoryDescriptor]['sections_with_matches']) && 
                foundEntryTypesRef.value['sub_categories'][subCategoryDescriptor]['sections_with_matches'].includes(sectionDescriptor));
      }
    })

    const isEntryTypeVisible = computed(() => {
      return (subCategoryDescriptor, entryTypeID) => {
        if (foundEntryTypesRef.value == null || foundEntryTypesRef.value['sub_categories'] == null) return true; //No filter always means it's visible
        return (foundEntryTypesRef.value['sub_categories'][subCategoryDescriptor] != null && 
                Array.isArray(foundEntryTypesRef.value['sub_categories'][subCategoryDescriptor]['entry_types_shown']) && 
                foundEntryTypesRef.value['sub_categories'][subCategoryDescriptor]['entry_types_shown'].includes(entryTypeID));
      }
    })

    const isSectionMatched = computed(() => {
      return (subCategoryDescriptor, sectionDescriptor) => {
        if (foundEntryTypesRef.value == null || foundEntryTypesRef.value['sub_categories'] == null) return false;
        return (foundEntryTypesRef.value['sub_categories'][subCategoryDescriptor] != null && 
                Array.isArray(foundEntryTypesRef.value['sub_categories'][subCategoryDescriptor]['sections_matching']) && 
                foundEntryTypesRef.value['sub_categories'][subCategoryDescriptor]['sections_matching'].includes(sectionDescriptor));
      }
    })

    const isEntryTypeMatched = computed(() => {
      return (subCategoryDescriptor, entryTypeID) => {
        if (foundEntryTypesRef.value == null || foundEntryTypesRef.value['sub_categories'] == null) return false;
        return (foundEntryTypesRef.value['sub_categories'][subCategoryDescriptor] != null && 
                Array.isArray(foundEntryTypesRef.value['sub_categories'][subCategoryDescriptor]['entry_types_matching']) && 
                foundEntryTypesRef.value['sub_categories'][subCategoryDescriptor]['entry_types_matching'].includes(entryTypeID));
      }
    })

    const visibleEntryTypeCount = computed(() => {
      return (subCategoryDescriptor) => {
        //If nothing is found in this subCategory, don't report any numbers
        if (foundEntryTypesRef.value == null || 
            foundEntryTypesRef.value['sub_categories'] == null || 
            foundEntryTypesRef.value['sub_categories'][subCategoryDescriptor] == null ||
            !(Array.isArray(foundEntryTypesRef.value['sub_categories'][subCategoryDescriptor]['entry_types_shown']))) return null; 
        let count = foundEntryTypesRef.value['sub_categories'][subCategoryDescriptor]['entry_types_shown'].length;
        if (count <= 0) return null;
        return count;
      }
    })

    const toggleEntryTypeHidden = function(entryTypeId, mainCategory, subCategoryDescriptor, entryTypeDescriptor) {
      emit('setEntryTypeHidden', {id: entryTypeId, main_category: mainCategory, stage: props.stageDescriptor, sub_category: subCategoryDescriptor, entry_type: entryTypeDescriptor, hidden: !(props.treatAsHidden)});
    }

    const selectEntryType = function(entryTypeId, mainCategory, subCategoryDescriptor, isSelected) {
      let entryTypeStructure = {main_category: mainCategory, stage: props.stageDescriptor, sub_category: subCategoryDescriptor, id: entryTypeId};

      if (Array.isArray(selectedEntryTypesRef.value)) {
        if (isSelected) selectedEntryTypesRef.value = _.unionWith(selectedEntryTypesRef.value, [entryTypeStructure], _.isEqual);
        else selectedEntryTypesRef.value = _.differenceWith(selectedEntryTypesRef.value, [entryTypeStructure], _.isEqual);
      }
    }

    const isSubCategoryUndefined = function(subCategory) {
      if (subCategory == null || subCategory['descriptor'] == null || subCategory['descriptor'] == 'null' || subCategory['descriptor'] == 'undefined') return true;
      return false;
    }

    const getLocalizedMainCategory = computed(() => {
      let mainCategories = props.localizedMainCategories;
      return function(id) {
        if (mainCategories == null) return null;
        return mainCategories[id];
      }
    })

    return {
      selectedEntryTypesRef,
      isEntryTypeSelected,
      isEntryTypeVisible,
      isSectionMatched,
      isEntryTypeMatched,
      isSubCategorySectionVisible,
      isSectionVisible,
      isSubCategoryVisible,
      visibleEntryTypeCount,
      toggleEntryTypeHidden,
      selectEntryType,
      getLocalizedMainCategory,
      isSubCategoryUndefined,
      eye,
      eyeOff,
      cloudDone
    };
  }
}
</script>

<style scoped>
.list-level-1 {
  margin-left: 20px;
  --color: var(--ion-color-primary-text);
  color: var(--color);
}

.list-level-2 {
  margin-left: 10px;
  color: var(--color);
}

.sub-category-container {
  --custom-border-width: 1px 0px 1px 0px;
}

.sub-category-container > ion-item-divider {
  --color: var(--ion-color-dark);
  --background: rgba(var(--ion-color-tertiary-rgb), 0.2);
  --padding-start: 10px;
  font-size: 0.75em;
  font-weight: bold;
}

.entry-type-container  > ion-item-divider {
  --color: var(--ion-color-dark);
  --background: rgba(var(--ion-color-primary-rgb), 0.2);
  --padding-start: 10px;
  font-style: italic;
}

.sub-category-label, .entry-type-label {
  font-weight: normal;
  white-space: normal;
}

.entry-type-label-container {
  margin-block: 10px;
}

.entry-type-main-category {
  color: var(--ion-color-medium);
  display: inline-block;
  font-size: 0.9em;
  margin-top: 5px;
}

.hidden {
  display: none;
}

hr.list-separator {
  height: 1px;
  background: var(--ion-color-medium-light, gray);
  margin: -1px 0 0 0;
  z-index: 2;
  position: relative;
}

.matching-indicator, .section-matching-indicator {
  color: var(--ion-color-tertiary-text);
  margin-inline-end: 8px;
  font-size: 16px;
  width: 16px;
  pointer-events: all;
}

ion-item {
  --background: var(--background);
  --padding-start: 5px;
}

ion-item.hiding-mode {
  pointer-events: none;
    /* Disable select on texts */
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.hide-button {
  height: 36px;
  margin: 9px 0px;
  margin-left: 10px;
}

.ios .hide-button {
  height: 33px;
  margin: 6px 0px;
  margin-left: 10px;
}

.cache-indicator {
  padding-inline-start: 5px;
  vertical-align: -2px;
  opacity: 0.6;
  color: var(--ion-color-primary);
}
</style>
