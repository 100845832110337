<template id="add-service-modal">
    <ion-page>
      <ion-header>
        <ion-toolbar>
          <ion-title id="title">
            {{ i18n.$t('services.add.title') }}
          </ion-title>
          <ion-buttons class="modal-confirmation-buttons" slot="end">
            <ExtendableChip
              class="cancel-add-services-button"
              :color="isModified ? 'danger' : 'dark'"
              :title="((isModified) ? i18n.$t('services.edit.discard_question') : undefined)"
              :extendOnClick="isModified"
              @extendedClick="closeModal()"
              :enableClosedClick="!(isModified)"
              @closedClick="closeModal()"
              extendToLeft
              button
              textIcon
              :collapseTimeout="5000"
              >
              <template v-slot:permanent>
                <ion-icon :icon="close" :alt="i18n.$t('services.edit.discard_question')"></ion-icon>
              </template>
            </ExtendableChip>

            <ion-button
              id="add-services-button"
              @click="closeModal(true)"
              fill="solid"
              :disabled="!isModified"
              shape="round"
              color="primary" >
              <ion-icon slot="icon-only" :icon="add"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-header>

      <ion-content>
        <ion-card v-if="customService">
          <!-- TODO Put ServiceItem here and enable editing of name there too -->
        </ion-card>
        <ion-card v-else>
          <ion-card-header>
            <ion-searchbar :placeholder="i18n.$t('services.add.search')" @keyup="blurOnEnter" @ionChange="setSearchTerm($event.target.value)"></ion-searchbar>
          </ion-card-header>
          <ServicesSelection ref="servicesSelectionRef" editMode :defaultServiceType="serviceType" @elementsUpdated="updateServices" :filterTerm="searchServicesInput"></ServicesSelection>
        </ion-card>
      </ion-content>
    </ion-page>
</template> <!-- TODO Add custom here too, just hide the service selection if custom and show form inputs! -->

<script>

import { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent, IonIcon, IonCard, IonCardHeader, IonSearchbar, modalController } from '@ionic/vue';
import { defineComponent, defineAsyncComponent, computed, ref } from 'vue';

const ServicesSelection = defineAsyncComponent(() => import('@/components/ServicesSelection.vue'));

import { blurOnEnter } from '@/utils/interaction';

import ExtendableChip from '@/components/ExtendableChip.vue';

import { close, add } from 'ionicons/icons';

import { useI18n } from "@/utils/i18n";

const AddServiceModal = defineComponent({
  name: 'AddServiceModal',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonContent, IonIcon, IonCard, IonCardHeader, IonSearchbar, ServicesSelection, ExtendableChip },
  props: {
    serviceType: {
      type: String,
      default: 'GOT'
    },
    customService: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const i18n = useI18n();

    const searchServicesInput = ref(null);

    const servicesSelectionRef = ref(null);

    const modifiedServices = ref([]);

    const isModified = computed(() => {
      return (modifiedServices.value != null && modifiedServices.value.length > 0);
    });

    const setSearchTerm = function(searchTerm) {
      (async () => searchServicesInput.value = searchTerm)();
    }

    const updateServices = function(changedServices) {
      if (changedServices != null) {
        modifiedServices.value = Object.values(changedServices);
      }
    }

    const closeModal = function(returnServices = false) {
      if (returnServices) {
        modalController.dismiss(modifiedServices.value);
      } else {
        modalController.dismiss();
      }
      
      if (servicesSelectionRef.value != null) {
        servicesSelectionRef.value.resetItems();
      }
      modifiedServices.value = [];
    }

    return {
      i18n,

      servicesSelectionRef,

      blurOnEnter,
      searchServicesInput,
      setSearchTerm,

      isModified,
      updateServices,

      closeModal,

      close,
      add
    };
  }
});

export async function openAddServiceModal(component, modalProps = {}){
  //Create a promise to signal the view is about to close to clear up resources
  let modalWillClose;
  const modalWillClosePromise = new Promise((resolve) => modalWillClose = resolve);
  if (component != null) {
    const modal = await modalController
      .create({
        backdropDismiss: false,
        component,
        componentProps: {
          ...modalProps,
          modalWillClosePromise
        }
      })
    modal.present();
    modal.onWillDismiss().then((data) => modalWillClose(data));
    return modal.onWillDismiss().then((data) => (data != null && data.data != null) ? data.data : {});
  }
}

export default AddServiceModal;
</script>

<style scoped>
ion-card-header {
  padding-inline: 5px;
}

.modal-confirmation-buttons > * {
  margin-left: clamp(5px, 1.5vw, 10px);
  margin-right: clamp(5px, 1.5vw, 10px);
}

.modal-confirmation-buttons ion-button {
  height: 32px;
  width: 32px;
  --padding-start: 0px;
  --padding-end: 0px;
  --border-width: 2px;
}

.modal-confirmation-buttons #add-services-button {
  margin-inline-end: 15px;
}


.ios #title {
  font-size: clamp(.9em, 4vw, 17px);
  font-weight: 600;
}

#title {
  font-size: clamp(.9em, 4vw, 20px);
  font-weight: 500;
  letter-spacing: 0.0125em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.cancel-add-services-button {
  --custom-size: 32px!important;
  margin-block: 0px;
  margin-inline: 10px;
}

.cancel-add-services-button ion-icon {
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
</style>